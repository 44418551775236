<template>
  <v-list-item :to="{ name: 'contract', params: { contractId: contract.id } }">
    <v-list-item-content>
      <v-list-item-title v-if="building">
        {{ building.name }} - N°{{ contract.number }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ countSheet }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>

import { mapState } from "pinia";
import { useBuildingsStore } from "@/store/modules/building";
import { useNetworksStore } from "@/store/modules/network";
import { useTechniciansStore } from "@/store/modules/technician";
import { useTypesStore } from "@/store/modules/type";

export default {
  props: [
    'contract'
  ],
  computed: {
    ...mapState(useBuildingsStore, [
      'getBuildingById',
    ]),
    ...mapState(useNetworksStore, [
      'getNetworkById',
    ]),
    ...mapState(useTypesStore, [
      'getTypeById',
    ]),
    ...mapState(useTechniciansStore, [
      'getTechnicianById',
    ]),
    building() {
      if (this.contract) {
        return this.getBuildingById(this.contract.building_id);
      }

      return false;
    },
    network() {
      if (this.contract) {
        return this.getNetworkById(this.contract.network_id);
      }

      return false;
    },
    type() {
      if (this.contract) {
        return this.getTypeById(this.contract.type_id);
      }

      return false;
    },
    technician() {
      if (this.contract) {
        return this.getTechnicianById(this.contract.technician_id);
      }

      return false;
    },
    countSheet() {
      if (this.contract && this.contract.count > 0) {
        return this.contract.count + ' fiches'
      }

      return this.contract.count + ' fiche'
    }
  }
}

</script>

<style>
</style>