import typeApi from '@/api/type'
import local from '@/database/local.js'
import { defineStore } from 'pinia'

export const useTypesStore = defineStore('types', {
  state: () => ({
    loading: false,
    types: [],
  }),
  actions: {
    emptyTypes() {
      this.types = []
    },
    getTypes() {
      this.loading = true
      local
        .types
        .toArray()
        .then(types => {
          this.types = types
          this.loading = false
        });
    },
    fetchTypes() {
      this.loading = true
      return typeApi
        .fetchTypes()
        .then((response) => {
          local.types.bulkPut(response.data.types)
          this.loading = false
        })
        .then(() => {
          this.getTypes()
        })
    },
  },
  getters: {
    getTypeById: (state) => {
      return (typeId) => state.types.find((type) => type.id === typeId)
    },
  },
})
