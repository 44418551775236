<template>
  <div v-show="showMenu">
    <v-menu
      left
      bottom
    >
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          :to="{ name: 'comment', params: { sheetId: sheetId, sheetsId: sheetsId }}"
        >
          <v-list-item-title>
            Ajouter un commentaire
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  computed: {
    sheetId() {
      return parseInt(this.$route.params.sheetId);
    },
    sheetsId() {
      return this.$route.params.sheetsId;
    },
    showMenu() {
      return true;
    },
  },
};
</script>
