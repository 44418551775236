<template>
  <v-dialog
    ref="dialog"
    v-model="modal"
    persistent
    width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        label="Date"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      first-day-of-week="1"
      scrollable
    >
      <v-spacer></v-spacer>
      <v-btn
        text
        color="primary"
        @click="modal = false"
      >
        Annuler
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="changeDate()"
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>

export default {
  props: [
    'currentDate',
    'intervention',
  ],
  data() {
    return {
      modal: false,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    }
  },
  mounted () {
    if (this.intervention) {
      this.date = this.intervention.intervention_date
    }
    this.$emit('update-date', this.date)
  },
  methods: {
    changeDate() {
      this.modal = false
      this.$emit('update-date', this.date)
    }
  }
}

</script>
