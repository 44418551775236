<template>
  <v-container>
    <v-text-field
      label="Livré (kg)"
      type="number"
      v-model="delivered_quantity"
      v-on:change="changedLabel"
    >
    </v-text-field>
    <v-text-field
      label="Mis (kg)"
      type="number"
      v-model="inserted_quantity"
      v-on:change="changedLabel"
    >
    </v-text-field>
    <v-text-field
      label="Reste (sac)"
      type="number"
      v-model="remaining_quantity"
      v-on:change="changedLabel"
    >
    </v-text-field>
    <v-text-field
      label="Compteur eau (m3)"
      type="number"
      v-model="meter_index"
      v-on:change="changedLabel"
    >
    </v-text-field>

    <v-text-field
      label="TH (°F)"
      type="number"
      v-model="hardness"
      v-on:change="changedLabel"
    >
    </v-text-field>

    <v-textarea
      label="Observation"
      v-model="observation"
      v-on:change="changedLabel"
    ></v-textarea>

    <v-select 
      v-model="selected_part"
      :items="replacedParts"
      item-value="name"
      item-text="name"
      attach 
      chips 
      label="Pièces remplacées" 
      multiple
      @focusout="changedLabel"
    ></v-select>

    <v-textarea
      v-if="checkPartSelected"
      label="Autres pièces remplacées"
      v-model="other_selected_part"
      @focusout="changedLabel"
    ></v-textarea>

  </v-container>
</template>

<script>

export default {
  props: [
    'replacedParts',
    'intervention',
  ],
  data() {
    return {
      delivered_quantity: '',
      inserted_quantity: '',
      remaining_quantity: '',
      hardness: '',
      meter_index: '',
      observation: '',
      selected_part: [],
      other_selected_part: '',
    }
  },
  mounted() {
    if (this.intervention) {
      this.inserted_quantity = this.intervention.inserted_quantity
      this.delivered_quantity = this.intervention.delivered_quantity
      this.remaining_quantity = this.intervention.remaining_quantity
      this.hardness = this.intervention.hardness,
      this.meter_index = this.intervention.meter_index
      this.selected_part = this.intervention.replaced_parts.split(', ')
      this.observation = this.intervention.observation

      if (this.selected_part[this.selected_part.length - 2] === 'Autre') {
        this.other_selected_part = this.selected_part.slice(-1);
      }

      this.$emit('softenerIntervention', {
        'id' : this.intervention.id,
        'delivered_quantity' : this.delivered_quantity,
        'inserted_quantity' : this.inserted_quantity,
        'remaining_quantity' : this.remaining_quantity,
        'meter_index' : this.meter_index,
        'replaced_parts' : this.selected_part.toString().replace(/,/g, ', '),
        'observation': this.observation,
      })
    }
  },
  computed: {
    checkPartSelected() {
      if (this.selected_part.includes('Autre')) {
        return true;
      }

      return false;
    }
  },
  methods: {
    changedLabel () {
      let data = {
        'delivered_quantity' : this.delivered_quantity,
        'inserted_quantity' : this.inserted_quantity,
        'remaining_quantity' : this.remaining_quantity,
        'hardness' : this.hardness,
        'meter_index' : this.meter_index,
        'replaced_parts' : this.other_selected_part ? this.selected_part.toString().replace(/,/g, ', ') + ', ' + this.other_selected_part : this.selected_part.toString().replace(/,/g, ', '),
        'observation': this.observation,
      }
      if (this.intervention) {
        data.id = this.intervention.id
      }

      this.$emit('softenerIntervention', data)
    },
  }
}

</script>
