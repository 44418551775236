import technicianApi from '@/api/technician'
import local from '@/database/local.js'
import { defineStore } from 'pinia';

export const useTechniciansStore = defineStore('technicians', {
  state: () => ({
    loading: false,
    technicians: [],
  }),
  actions: {
    emptyTechnicians() {
      this.technicians = []
    },
    getTechnicians() {
      local.technicians.toArray().then(technicians => {
        this.technicians = technicians
      });
    },
    fetchTechnicians() {
      return technicianApi
        .fetchTechnicians()
        .then((response) => {
          local.technicians.bulkPut(response.data.technicians)
        })
        .then(() => {
          this.getTechnicians()
        });
    },
  },
  getters: {
    getTechnicianById: (state) => {
      return (technicianId) => state.technicians.find((technician) => technician.id === technicianId)
    },
  },
})
