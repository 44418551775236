import apiClient from '@/api/client';

export default {
  fetchTours() {
    return apiClient.get('/tours')
  },
  fetchTour(id) {
    return apiClient.get('/tours/' + id)
  },
}
