<template>
  <v-container>
    <v-text-field
      label="Quantité de produit livré"
      type="number"
      v-model="delivered_quantity"
      required
      v-on:change="changedLabel"
    >
    </v-text-field>
    
    <v-text-field
      label="Stock restant"
      type="number"
      v-model="remaining_quantity"
      required
      v-on:change="changedLabel"
    >
    </v-text-field> 
    
    <v-text-field
      label="Index compteur (m3)"
      type="number"
      v-model="meter_index"
      v-on:change="changedLabel"
    >
    </v-text-field>
    
    <v-select 
      v-model="replaced_parts"
      :items="replacedParts"
      item-value="name"
      item-text="name"
      attach 
      chips 
      label="Pièces remplacées" 
      multiple
      @focusout="changedLabel"
    ></v-select>

    <v-textarea
      v-if="checkPartReplaced"
      label="Autres pièces remplacées"
      v-model="other_replaced_parts"
      @focusout="changedLabel"
    ></v-textarea>

    <v-select 
      v-model="observation"
      :items="observations"
      item-value="name"
      item-text="name"
      attach 
      chips 
      label="Observations" 
      multiple
      @focusout="changedLabel"
    ></v-select>

    <v-textarea
      v-if="checkObservationsSelected"
      label="Autres observations"
      v-model="other_observation"
      @focusout="changedLabel"
    ></v-textarea>

  </v-container>
</template>

<script>

export default {
  props: [
    'replacedParts',
    'observations',
    'intervention'
  ],
  data() {
    return {
      delivered_quantity: '',
      remaining_quantity: '',
      meter_index: '',
      observation: '',
      replaced_parts: '',
      other_replaced_parts: '',
      other_observation: '',
    }
  },
  mounted () {
    if (this.intervention) {
      this.delivered_quantity = this.intervention.delivered_quantity
      this.remaining_quantity = this.intervention.remaining_quantity
      this.meter_index = this.intervention.meter_index
      this.replaced_parts = this.intervention.replaced_parts.split(', ')
      this.observation = this.intervention.observation.split(', ')

      if (this.replaced_parts.includes('Autre')) {
        this.other_replaced_parts = this.replaced_parts.pop();
      }

      if (this.observation.includes('Autre')) {
        this.other_observation = this.observation.pop();
      }

      this.$emit('filmIntervention', {
        'id' : this.intervention.id,
        'delivered_quantity' : this.delivered_quantity,
        'remaining_quantity' : this.remaining_quantity,
        'meter_index' : this.meter_index,
        'replaced_parts' : this.replaced_parts.toString().replace(/,/g, ', '),
        'observation': this.observation.toString().replace(/,/g, ', ')
      })
    }
  },
  computed: {
    checkPartReplaced() {
      if (this.replaced_parts.includes('Autre')) {
        return true;
      }

      return false
    },
    checkObservationsSelected() {
      if (this.observation.includes('Autre')) {
        return true
      }

      return false
    }
  },
  methods: {
    changedLabel () {
      let data = { 
        'delivered_quantity' : this.delivered_quantity,
        'remaining_quantity' : this.remaining_quantity,
        'meter_index' : this.meter_index,
        'replaced_parts' : this.replaced_parts.toString().replace(/,/g, ', ') + (this.other_replaced_parts ? ', ' + this.other_replaced_parts : ''),
        'observation' : this.observation.toString().replace(/,/g, ', ') + (this.other_observation ? ', ' + this.other_observation : ''),
      }

      if (this.intervention) {
        data.id = this.intervention.id
      }

      this.$emit('filmIntervention', data)
    },
  }
}

</script>
