<template>
  <div>
    <v-container>
      <div class="headline mt-1">Immeubles</div>
      <v-text-field
        placeholder="Rechercher"
        append-icon="mdi-magnify"
        v-model="search"
        v-on:keyup="getSearch"
      >
      </v-text-field>
    </v-container>
    <template v-if="hasBuildings">
      <building-list :buildings="currentBuildings"></building-list>
    </template>
    <template v-else>
      <v-container>
        <v-alert
          border="left"
          type="warning"
        >
          Aucune immeuble dans la base de données.
        </v-alert>
      </v-container>
    </template>
    <v-container v-if="hasBuildings">
      <v-pagination
        v-model="page"
        :length="currentPage"
        :total-visible="5"
      ></v-pagination>
    </v-container>
  </div>
</template>

<script>
import BuildingList from "@/components/building/List";
import { mapState, mapActions } from "pinia";
import { useBuildingsStore } from "@/store/modules/building";

export default {
  data() {
    return {
      search: "",
      page: 1,
      perPage: 50,
      timer: 0,
    };
  },
  components: {
    "building-list": BuildingList,
  },
  computed: {
    ...mapState(useBuildingsStore, {
      buildings: "buildings",
    }),
    currentBuildings() {
      return this.buildings.slice(
        (this.page - 1) * this.perPage,
        this.page * this.perPage
      );
    },
    currentPage() {
      return Math.ceil(this.buildings.length / this.perPage);
    },
    hasBuildings() {
      if (this.buildings && this.buildings.length) {
        return true
      }

      return false
    }
  },
  mounted() {
    this.getBuildings();
  },
  methods: {
    ...mapActions(useBuildingsStore, ["getBuildings", "searchBuildings"]),
    getSearch() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }

      this.timer = setTimeout(() => {
        this.searchBuildings(this.search);
      }, 200);
    },
  },
};
</script>
