<template>
  <div>
    <v-container v-if="building">
      <div class="headline mt-1">{{ building.name }}</div>
    </v-container>
    <v-list dense>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content>Numéro</v-list-item-content>
        <v-list-item-content class="justify-end">
          {{ building.number }}
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content>Code postal</v-list-item-content>
        <v-list-item-content class="justify-end">
          {{ building.zip }}
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content>Localité</v-list-item-content>
        <v-list-item-content class="justify-end">
          {{ building.locality }}
        </v-list-item-content>
      </v-list-item>
      <template v-if="buildingParent">
        <v-divider></v-divider>
        <v-list-item
          :to="{
            name: 'building',
            params: { buildingId: buildingParent.id },
          }"
        >
          <v-list-item-content>Groupe d'immeubles</v-list-item-content>
          <v-list-item-content class="justify-end">
            {{ buildingParent.name }}
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
    <v-container v-if="building.info">
      <div class="text-h6 mt-2">Note d'information</div>
      <v-input>{{ building.info }}</v-input>
    </v-container>
    <v-container v-if="sheets && sheets.length">
      <div class="text-h6 mt-2">Fiches</div>
      <sheet-list :sheets="sheets" :sheetsId="sheetsId"></sheet-list>
    </v-container>
    <v-container v-if="buildings.length">
      <div class="text-h6 mt-2">Immeubles du groupe</div>
      <building-list :buildings="buildings"></building-list>
    </v-container>
    <v-bottom-navigation :fixed="true">
      <v-btn value="recent" :to="{ name: 'info', params : { buildingId: buildingId, sheetsId: sheetsId } }">
        <span>Ajouter une info</span>
        <v-icon>mdi-information</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { useBuildingsStore } from "@/store/modules/building";
import { useContractsStore } from "@/store/modules/contract";
import { useSheetsStore } from "@/store/modules/sheet";
import BuildingList from "@/components/building/List";
import SheetList from "@/components/sheet/List";

export default {
  components: {
    "building-list": BuildingList,
    "sheet-list": SheetList,
  },
  mounted() {
    this.getBuildings()
  },
  computed: {
    ...mapState(useBuildingsStore, [
      'getBuildingById',
      'getBuildingsByBuildingId',
      'getBuildings',
    ]),
    ...mapState(useContractsStore, [
      'getContractsByBuildingId',
    ]),
    ...mapState(useSheetsStore, [
      'getSheetsByContractId',
    ]),
    buildingId() {
      return parseInt(this.$route.params.buildingId);
    },
    sheetsId() {
      return this.$route.params.sheetsId;
    },
    building() {
      return this.getBuildingById(this.buildingId);
    },
    buildings() {
      return this.getBuildingsByBuildingId(this.buildingId);
    },
    buildingParent() {
      return this.getBuildingById(this.building.building_id);
    },
    contracts() {
      return this.getContractsByBuildingId(this.buildingId);
    },
    sheets() {
      return this.getSheetsByContractId(this.contracts[0].id)
    }
  },
};
</script>
