<template>
  <div>
    <v-container>
      <div class="headline mt-1">Fiches</div>
      <v-text-field
        placeholder="Rechercher"
        append-icon="mdi-magnify"
        v-model="search"
        v-on:keyup="getSearch"
      >
      </v-text-field>
    </v-container>
    <template v-if="hasSheets">
      <sheet-list :sheets="currentSheets" :sheetsId="sheetsId"></sheet-list>
    </template>
    <template v-else>
      <v-container>
        <v-alert
          border="left"
          type="warning"
        >
          Aucune fiche dans la base de données.
        </v-alert>
      </v-container>
    </template>
    <v-container v-if="hasSheets">
      <v-pagination
        v-model="page"
        :length="currentPage"
        :total-visible="5"
      ></v-pagination>
    </v-container>
  </div>
</template>

<script>

import SheetList from "@/components/sheet/List";
import { mapActions, mapState } from "pinia";
import { useSheetsStore } from "@/store/modules/sheet";
import { useContractsStore } from "@/store/modules/contract";
import { useBuildingsStore } from "@/store/modules/building";
import { useTypesStore } from "@/store/modules/type";
import { useNetworksStore } from "@/store/modules/network";
import { useTechniciansStore } from "@/store/modules/technician";
import { useTechnicalsStore } from "@/store/modules/technical";
import { useInterventionsStore } from "@/store/modules/intervention";

export default {
  data() {
    return {
      search: "",
      page: 1,
      perPage: 50,
      timer: 0,
    };
  },
  components: {
    SheetList,
  },
  computed: {
    ...mapState(useSheetsStore, [
      'sheets',
    ]),
    currentSheets() {
      return this.sheets.slice(
        (this.page - 1) * this.perPage,
        this.page * this.perPage
      );
    },
    currentPage() {
      return Math.ceil(this.sheets.length / this.perPage);
    },
    hasSheets() {
      if (this.sheets && this.sheets.length) {
        return true
      }

      return false
    },
    sheetsId() {
      return this.sheets.map(sheet => sheet.id)
    }
  },
  mounted() {
    this.getSheets();
    this.getContracts();
    this.getBuildings();
    this.getTypes();
    this.getNetworks();
    this.getTechnicians();
    this.getTechnicals();
    this.getInterventions();
  },
  methods: {
    ...mapActions(useSheetsStore, [
      'getSheets',
      'searchSheets',
    ]),
    ...mapActions(useContractsStore, [
      'getContracts',
    ]),
    ...mapActions(useBuildingsStore, [
      'getBuildings',
    ]),
    ...mapActions(useTypesStore, [
      'getTypes',
    ]),
    ...mapActions(useNetworksStore, [
      'getNetworks',
    ]),
    ...mapActions(useTechniciansStore, [
      'getTechnicians',
    ]),
    ...mapActions(useTechnicalsStore, [
      'getTechnicals',
    ]),
    ...mapActions(useInterventionsStore, [
      'getInterventions',
    ]),
    getSearch() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }

      this.timer = setTimeout(() => {
        this.searchSheets(this.search);
      }, 200);
    },
  },
}

</script>
