<template>
  <div>
    <v-container v-if="true">
      <div class="text-h6 mt-2">Note d'information</div>
      <v-text-field
        v-model="building.info"
        label="Ajouter une info"
      ></v-text-field>
      <v-layout class="mt-2 mb-4" justify-end>
        <v-btn depressed class="mr-2" @click="cancel()">
          Annuler
        </v-btn>
        <v-btn depressed color="primary" @click="save()">
          Enregistrer
        </v-btn>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useBuildingsStore } from "@/store/modules/building";

export default {
  computed: {
    ...mapState(useBuildingsStore, [
      'getBuildingById',
    ]),
    buildingId() {
      return parseInt(this.$route.params.buildingId);
    },
    building() {
      return this.getBuildingById(this.buildingId);
    },
    sheetsId() {
      return this.$route.params.sheetsId
    }
  },
  methods: {
    ...mapActions(useBuildingsStore, [
      'storeInfo',
    ]),
    save() {
      this.storeInfo(this.buildingId, this.building.info)
      this.backToBuilding()
    },
    cancel() {
      this.backToBuilding()
    },
    backToBuilding() {
      this.$router.push({
        name: "building",
        params: {
          buildingId: this.buildingId,
          sheetsId : this.sheetsId,
        },
      });
    }
  },
};
</script>
