import apiClient from "@/api/client";

export default {
  fetchSheets() {
    return apiClient.get("/sheets");
  },
  fetchSheet(id) {
    return apiClient.get("/sheets/" + id);
  },
  updateSheet(sheet) {
    return apiClient.put('/sheets/' + sheet.id, sheet)
  },
};
