var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[(_vm.building)?_c('div',{staticClass:"headline mt-1"},[_vm._v(_vm._s(_vm.building.name))]):_vm._e()]),_c('v-list',{attrs:{"dense":""}},[_c('v-divider'),_c('v-list-item',[_c('v-list-item-content',[_vm._v(" Technicien ")]),(_vm.technician)?_c('v-list-item-content',{staticClass:"justify-end"},[_vm._v(" "+_vm._s(_vm.technician.first_name)+" "+_vm._s(_vm.technician.last_name)+" ")]):_vm._e()],1),_c('v-divider'),(_vm.contract && _vm.building)?_c('v-list-item',{attrs:{"to":{
        name: 'building',
        params: { buildingId: _vm.contract.building_id },
      }}},[_c('v-list-item-content',[_vm._v(" Immeuble ")]),_c('v-list-item-content',{staticClass:"justify-end"},[_vm._v(" "+_vm._s(_vm.building.name)+" ")])],1):_vm._e(),_c('v-divider'),_c('v-list-item',[_c('v-list-item-content',[_vm._v(" Numéro ")]),_c('v-list-item-content',{staticClass:"justify-end"},[_vm._v(" "+_vm._s(_vm.contract ? "N°" + _vm.contract.number : "")+" ")])],1),_c('v-divider'),_c('v-list-item',[_c('v-list-item-content',[_vm._v(" Localité ")]),(_vm.building)?_c('v-list-item-content',{staticClass:"justify-end"},[_vm._v(" "+_vm._s(_vm.building.zip)+" "+_vm._s(_vm.building.locality)+" ")]):_vm._e()],1),_c('v-divider')],1),(_vm.sheets.length)?_c('v-container',[_c('div',{staticClass:"text-h6 mt-2"},[_vm._v("Fiches")])]):_vm._e(),_c('sheet-list',{attrs:{"sheets":_vm.sheets,"sheetsId":_vm.sheetsId}}),_c('v-bottom-navigation',{attrs:{"fixed":true}},[_c('v-btn',{attrs:{"value":"recent","to":{ name: 'contracts' }}},[_c('span',[_vm._v("Contrats")]),_c('v-icon',[_vm._v("mdi-file-sign")])],1),(
        _vm.type && _vm.technical != undefined && !_vm.technical.hasOwnProperty('deleted')
      )?_c('v-btn',{attrs:{"value":"recent","to":{
        name: 'technical_update',
        params: {
          typeId: _vm.type.id,
          technicalId: _vm.technical.id
        },
      }}},[_c('span',[_vm._v("Informations techniques")]),_c('v-icon',[_vm._v("mdi-wrench")])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }