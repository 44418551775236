import networkApi from '@/api/network'
import local from '@/database/local.js'
import { defineStore } from 'pinia';

export const useNetworksStore = defineStore('networks', {
  state: () => ({
    loading: false,
    networks: [],
  }),
  actions: {
    emptyNetworks() {
      this.networks = []
    },
    getNetworks() {
      this.loading = true
      local
        .networks
        .toArray()
        .then(networks => {
          this.networks = networks
          this.loading = false
        })
    },
    fetchNetworks() {
      this.loading = true
      return networkApi
        .fetchNetworks()
        .then((response) => {
          local.networks.bulkPut(response.data.networks)
          this.loading = false
        })
        .then(() => {
          this.getNetworks()
        })
    },
  },
  getters: {
    getNetworkById: (state) => {
      return (networkId) => state.networks.find((network) => network.id === networkId)
    },
  },
})
