<template>
  <div>
    <v-container>
      <div class="headline mt-1" v-if="sheet && building">
        {{ sheet.name }}
        <div class="subtitle-1">{{ subtitle }}</div>
      </div>
    </v-container>
    <v-list dense>
      <v-divider></v-divider>
      <template v-if="building">
        <v-list-item @click="goToBuilding()">
          <v-list-item-content>Immeuble</v-list-item-content>
          <v-list-item-content class="justify-end">
            {{ building.name }}
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>Localité</v-list-item-content>
          <v-list-item-content class="justify-end">
            {{ building.zip }} {{ building.locality }}
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </template>
      <template v-if="contract">
        <v-list-item :to="{ name: 'contract', params: { contractId: contract.id, sheetsId: sheetsId }}">
          <v-list-item-content>Contrat</v-list-item-content>
          <v-list-item-content class="justify-end">
            N°{{ contract.number }}
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </template>
      <template>
        <v-list-item>
          <v-list-item-content>Commentaire</v-list-item-content>
          <v-list-item-content class="justify-end">
            {{ sheet.comment ? sheet.comment : '-' }}
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </template>
    </v-list>
    <intervention-table
      class="mb-4"
      :interventions="interventions"
      :type-id="type.id"
      v-if="type != undefined"
      :sheetsId="sheetsId"
      :sheetId="sheetId"
    ></intervention-table>
    <v-bottom-navigation :fixed="true">
      <template v-if="sheetsId">
        <v-btn
          @click="changeSheet(indexCurrentSheetId - 1)"
        >
          <span>Précédent</span>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </template>
      <template v-if="tourId">
        <v-btn :to="{ name: 'tour', params: { tourId: tourId }}">
          <span>Tournée</span>
          <v-icon>mdi-arrow-decision</v-icon>
        </v-btn>
      </template>
      <template v-else>
        <v-btn :to="{ name: 'tours' }">
          <span>Tournées</span>
          <v-icon>mdi-arrow-decision</v-icon>
        </v-btn>
      </template>
      <v-btn
        v-if="type"
        :to="{
          name: 'intervention_create',
          params: {
            sheetId: sheetId,
            typeId: type.id,
            sheetsId: sheetsId,
          },
        }"
      >
        <span>Intervention</span>
        <v-icon>mdi-plus-thick</v-icon>
      </v-btn>
      <v-btn
        v-if="building"
        @click="goToBuilding()"
      >
        <span>Immeuble</span>
        <v-icon>mdi-office-building-outline</v-icon>
      </v-btn>
      <v-btn
        v-if="
          type != undefined &&
          technical != undefined &&
          !technical.hasOwnProperty('deleted')
        "
        :to="{
          name: 'technical_update',
          params: {
            typeId: type.id,
            technicalId: technical ? technical.id : '',
            sheetsId: sheetsId,
          },
        }"
      >
        <span>Infos techniques</span>
        <v-icon>mdi-wrench</v-icon>
      </v-btn>
      <template v-if="sheetsId">
        <v-btn
          @click="changeSheet(indexCurrentSheetId + 1)"
        >
          <span>Suivante</span>
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>          
      </template>
    </v-bottom-navigation>
  </div>
</template>

<script>

import { mapActions, mapState } from "pinia";
import InterventionTable from "@/components/intervention/Table";
import { useSheetsStore } from "@/store/modules/sheet";
import { useContractsStore } from "@/store/modules/contract";
import { useBuildingsStore } from "@/store/modules/building";
import { useTypesStore } from "@/store/modules/type";
import { useNetworksStore } from "@/store/modules/network";
import { useTechniciansStore } from "@/store/modules/technician";
import { useTechnicalsStore } from "@/store/modules/technical";
import { useInterventionsStore } from "@/store/modules/intervention";

export default {
  components: {
    InterventionTable,
  },
  mounted() {
    this.getSheets();
    this.getContracts();
    this.getBuildings();
    this.getTypes();
    this.getNetworks();
    this.getTechnicians();
    this.getTechnicals();
    this.getInterventions();
  },
  methods: {
    ...mapActions(useSheetsStore, [
      'getSheets',
    ]),
    ...mapActions(useContractsStore, ["getContracts"]),
    ...mapActions(useBuildingsStore, ["getBuildings"]),
    ...mapActions(useTypesStore, ["getTypes"]),
    ...mapActions(useNetworksStore, ["getNetworks"]),
    ...mapActions(useTechniciansStore, ["getTechnicians"]),
    ...mapActions(useTechnicalsStore, ["getTechnicals"]),
    ...mapActions(useInterventionsStore, ["getInterventions"]),
    changeSheet(index) {
      if (index >= 0 && index < this.sheetsId.length) {
        this.$router.push({
          name: 'sheet',
          params: {
            sheetId: this.sheetsId[index],
            sheetsId: this.sheetsId,
          },
        });
      }
    },
    goToBuilding() {
      this.$router.push({
        name: 'building',
        params: {
          buildingId: this.building.id,
          sheetsId: this.sheetsId,
        }
      })
    }
  },
  computed: {
    ...mapState(useSheetsStore, [
      'getSheetById',
    ]),
    ...mapState(useContractsStore, [
      'getContractById'
    ]),
    ...mapState(useBuildingsStore, [
      'getBuildingById',
    ]),
    ...mapState(useTechniciansStore, [
      'getTechnicianById',
    ]),
    ...mapState(useTypesStore, [
      'getTypeById',
    ]),
    ...mapState(useNetworksStore, [
      'getNetworkById',
    ]),
    ...mapState(useInterventionsStore, [
      'getInterventionsBySheetId',
    ]),
    ...mapState(useTechnicalsStore, [
      'getTechnicalsBySheetId',
    ]),
    sheetId() {
      return parseInt(this.$route.params.sheetId);
    },
    sheetsId() {
      return this.$route.params.sheetsId;
    },
    indexCurrentSheetId() {
      return this.sheetsId.indexOf(this.sheetId)
    },
    sheet() {
      return this.getSheetById(this.sheetId);
    },
    building() {
      if (this.contract) {
        return this.getBuildingById(this.contract.building_id);
      }

      return false;
    },
    contract() {
      if (this.sheet) {
        return this.getContractById(this.sheet.contract_id);
      }

      return false;
    },
    technician() {
      if (this.sheet) {
        return this.getTechnicianById(this.sheet.technician_id);
      }

      return false;
    },
    type() {
      if (this.sheet) {
        return this.getTypeById(this.sheet.type_id);
      }

      return false;
    },
    network() {
      if (this.sheet) {
        return this.getNetworkById(this.sheet.network_id);
      }

      return false;
    },
    interventions() {
      if (this.sheet) {
        return this.getInterventionsBySheetId(this.sheet.id);
      }

      return false;
    },
    technical() {
      if (this.sheet) {
        return this.getTechnicalsBySheetId(this.sheet.id);
      }

      return false;
    },
    tourId() {
      if (this.sheet && this.sheet.tours.length == 1) {
        return this.sheet.tours[0]
      }

      return false
    },
    subtitle() {
      let subtitle = []
      if (this.type) {
       subtitle.push(this.type.name)
      }

      if (this.network) {
        subtitle.push(this.network.name)
      }

      return subtitle.join(' - ')
    },
  },
}

</script>
