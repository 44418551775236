import tourApi from '@/api/tour'
import local from '@/database/local.js'
import { defineStore } from 'pinia';

export const useToursStore = defineStore('tours', {
  state: () => ({
    loading: false,
    tours: [],
    tour: false,
  }),
  actions: {
    emptyTours() {
      this.tours = []
    },
    getTours() {
      this.loading = true
      local
        .tours
        .toArray()
        .then(tours => {
          this.tours = tours
          this.loading = false
        });
    },
    searchTours(search) {
      local
        .tours
        .filter((tour) => {
          return tour.name?.toLowerCase().indexOf(search.toLowerCase()) >= 0
        })
        .toArray()
        .then(tours => {
          this.tours = tours
          this.loading = false
        })
    },
    fetchTours() {
      this.loading = true
      return tourApi
        .fetchTours()
        .then((response) => {
          local.tours.bulkPut(response.data.tours)
          this.loading = false
        })
        .then(() => {
          this.getTours()
        })
    },
  },
  getters: {
    getTourById: (state) => {
      return (tourId) => state.tours.find((tour) => tour.id === tourId)
    },
    getToursByContractId: (state) => {
      return (contractId) => state.tours.filter((tour) => tour.contracts.find((tourContractId) => tourContractId === contractId))
    },
    getTechnicianTours: (state) => {
      return (technicianId) => state.tours.filter((tour) => tour.technician_id == technicianId)
    }
  },
})
