<template>
  <v-list>
    <template v-for="tour in tours">
      <tour-list-tile
        :key="tour.id"
        :tour="tour"
      ></tour-list-tile>
      <v-divider :key="'divider-' + tour.id"></v-divider>
    </template>
  </v-list>
</template>

<script>

import TourListTile from "@/components/tour/ListTile";

export default {
  props: [
    'tours'
  ],
  components: {
    TourListTile,
  },
}

</script>
