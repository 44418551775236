<template>
  <div>
    <v-container v-if="true">
      <div class="text-h6 mt-2">Commentaire</div>
      <v-textarea
        v-model="sheet.comment"
        label="Ajouter un commentaire"
      ></v-textarea>
      <v-layout class="mt-2 mb-4" justify-end>
        <v-btn depressed class="mr-2" @click="cancel()">
          Annuler
        </v-btn>
        <v-btn depressed color="primary" @click="save()">
          Enregistrer
        </v-btn>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useSheetsStore } from "@/store/modules/sheet";

export default {
  computed: {
    ...mapState(useSheetsStore, [
      'getSheetById',
    ]),
    sheetId() {
      return parseInt(this.$route.params.sheetId);
    },
    sheetsId() {
      return this.$route.params.sheetsId;
    },
    sheet() {
      return this.getSheetById(this.sheetId);
    },
  },
  methods: {
    ...mapActions(useSheetsStore, [
      'storeComment',
    ]),
    save() {
      this.storeComment(this.sheetId, this.sheet.comment)
      this.backTosheet()
    },
    cancel() {
      this.backTosheet()
    },
    backTosheet() {
      this.$router.push({
        name: "sheet",
        params: {
          sheetId: this.sheetId,
          sheetsId: this.sheetsId,
        },
      });
    }
  },
};
</script>
