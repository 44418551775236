import { defineStore } from 'pinia'

export const useUsersStore = defineStore('users', {
  state: () => ({
    user: {},
  }),
  actions: {
    getUser() {
      this.user.id = localStorage.getItem('user_id')
      this.user.full_name = localStorage.getItem('user_full_name')
    }
  },
})
