<template>
  <v-container fill-height>
    <v-layout align-center column>
      <v-form class="login-form">
        <v-text-field
          v-model="auth.email"
          label="E-mail"
          required
          :type="'email'"
        >
        </v-text-field>
        <v-text-field
          v-model="auth.password"
          label="Mot de passe"
          required
          :type="'password'"
        >
        </v-text-field>
      </v-form>
      <v-btn
        large
        color="primary"
        :loading="loading"
        :disabled="loading"
        @click="login"
      >
        Se connecter
      </v-btn>
      <router-link :to="{ name: 'password_forgot' }" class="mt-4">
        Mot de passe oublié ?
      </router-link>
    </v-layout>
  </v-container>
</template>

<script>

import Auth from '@/api/auth';

export default {
  data() {
    return {
      auth: {
        email: "",
        password: "",
        device_name: navigator.userAgent,
      },
      valid: false,
      loading: false,
    };
  },
  mounted() {
    localStorage.removeItem('user');
  },
  methods: {
    login() {
      Auth.login(this.auth).then((response) => {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user_id', response.data.user.id);
        localStorage.setItem('user_full_name', response.data.user.name);
        this.$router.push({
          name: 'home'
        });
      });
    },
  },
};
</script>

<style>
.login-form {
  width: 100%;
  max-width: 280px;
}
</style>
