<template>
  <v-container>
    <p v-if="lastSync">
      <b>Dernière synchronisation le {{ lastSync }}</b>
    </p>
    <v-alert class="mb-2" type="error" v-if="isOffline">
      Vous êtes hors-ligne
    </v-alert>
    <v-btn
      :disabled="isOffline"
      class="mb-2"
      block
      depressed
      @click="fetchAll()"
      :loading="sync.loading"
    >
      Syncronisation des données
    </v-btn>
    <v-alert class="mb-2" type="success" v-if="!interventionToSync.length && !buildingToSync.length && !sheetToSync.length">
      Aucune syncronisation à faire
    </v-alert>
    <v-alert class="mb-2" type="warning" v-if="interventionToSync.length">
      Interventions à synchroniser : {{ interventionToSync.length }}
    </v-alert>
    <v-alert class="mb-2" type="warning" v-if="buildingToSync.length">
      Immeubles à synchroniser : {{ buildingToSync.length }}
    </v-alert>
    <v-alert class="mb-2" type="warning" v-if="sheetToSync.length">
      Fiches à synchroniser : {{ sheetToSync.length }}
    </v-alert>
    <v-btn
      :disabled="isOffline"
      class="mb-2"
      block
      depressed
      :loading="update.loading"
      @click="setAll()"
    >
      Envoyer les mises à jours
    </v-btn>
    <v-alert
      type="success"
      :value="update.message"
    >
      Vos données ont bien été envoyées
    </v-alert>
    <div class="text-xs-center my-4" v-if="sync.message">
      <v-alert color="info" type="info">
        Données de l'application en chargement
        <v-progress-linear color="primary" indeterminate></v-progress-linear>
      </v-alert>
    </div>
    <v-list two-line class="mx-n3">
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Immeubles</v-list-item-title>
          <v-list-item-subtitle>{{ buildings.length }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon :loading="loadingBuilding">
            <v-icon color="grey lighten-1">mdi-check-bold</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Contrats</v-list-item-title>
          <v-list-item-subtitle>{{ contracts.length }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon :loading="loadingContract">
            <v-icon color="grey lighten-1">mdi-check-bold</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Fiches</v-list-item-title>
          <v-list-item-subtitle>{{ sheets.length }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon :loading="loadingSheet">
            <v-icon color="grey lighten-1">mdi-check-bold</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Interventions</v-list-item-title>
          <v-list-item-subtitle>{{
            interventions.length
          }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon :loading="loadingIntervention">
            <v-icon color="grey lighten-1">mdi-check-bold</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Tournées</v-list-item-title>
          <v-list-item-subtitle>{{ tours.length }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon :loading="loadingTour">
            <v-icon color="grey lighten-1">mdi-check-bold</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Informations techniques</v-list-item-title>
          <v-list-item-subtitle>{{ technicals.length }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon :loading="loadingTechnical">
            <v-icon color="grey lighten-1">mdi-check-bold</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Produits</v-list-item-title>
          <v-list-item-subtitle>{{ products.length }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon :loading="loadingProduct">
            <v-icon color="grey lighten-1">mdi-check-bold</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Types</v-list-item-title>
          <v-list-item-subtitle>{{ types.length }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon :loading="loadingType">
            <v-icon color="grey lighten-1">mdi-check-bold</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Reséaux</v-list-item-title>
          <v-list-item-subtitle>{{ networks.length }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon :loading="loadingNetwork">
            <v-icon color="grey lighten-1">mdi-check-bold</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Pièces</v-list-item-title>
          <v-list-item-subtitle>{{ parts.length }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon :loading="loadingPart">
            <v-icon color="grey lighten-1">mdi-check-bold</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
    </v-list>
    <v-btn 
      block
      depressed
      class="mt-1"
      @click="dialog = true"
      v-if="!loadingContract"
    >
      Supprimer la base de données
    </v-btn>
    <v-dialog
      v-model="dialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          Suppression
        </v-card-title>

        <v-card-text>
          Êtes-vous certain de vouloir supprimer la base de données ?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            @click="dialog = false"
          >
            Retour
          </v-btn>

          <v-btn
            color="red"
            text
            @click="deleteDB()"
          >
            Confirmer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

import { mapActions, mapState } from "pinia";
import { useBuildingsStore } from "@/store/modules/building";
import { useContractsStore } from "@/store/modules/contract";
import { useInterventionsStore } from "@/store/modules/intervention";
import { useToursStore } from "@/store/modules/tour";
import { useNetworksStore } from "@/store/modules/network";
import { useObservationsStore } from "@/store/modules/observation";
import { usePartsStore } from "@/store/modules/part";
import { useProductsStore } from "@/store/modules/product";
import { useSheetsStore } from "@/store/modules/sheet";
import { useTechnicalsStore } from "@/store/modules/technical";
import { useTechniciansStore } from "@/store/modules/technician";
import { useTypesStore } from "@/store/modules/type";
import local from "@/database/local.js";
import moment from 'moment'

export default {
  data() {
    return {
      lastSync: '',
      interventionToSync: [],
      buildingToSync: [],
      sheetToSync: [],
      sync: {
        loading: false,
        message: false,
      },
      update: {
        loading: false,
        message: false,
      },
      dialog: false,
    };
  },
  mounted() {
    if (localStorage.getItem('lastSync')) {
      this.lastSync = localStorage.getItem('lastSync')
    }

    this.countDataToSync()
  },
  computed: {
    ...mapState(useContractsStore, {
      contracts: "contracts",
      loadingContract: "loading",
    }),
    ...mapState(useSheetsStore, {
      sheets: "sheets",
      loadingSheet: "loading",
    }),
    ...mapState(useInterventionsStore, {
      interventions: "interventions",
      loadingIntervention: "loading",
    }),
    ...mapState(useToursStore, {
      tours: "tours",
      loadingTour: "loading",
    }),
    ...mapState(useTechnicalsStore, {
      technicals: "technicals",
      loadingTechnical: "loading",
    }),
    ...mapState(useBuildingsStore, {
      buildings: "buildings",
      loadingBuilding: "loading",
    }),
    ...mapState(useProductsStore, {
      products: "products",
      loadingProduct: "loading",
    }),
    ...mapState(useTypesStore, {
      types: "types",
      loadingType: "loading",
    }),
    ...mapState(usePartsStore, {
      parts: "parts",
      loadingPart: "loading",
    }),
    ...mapState(useObservationsStore, {
      observations: "observations",
      loadingObservation: "loading",
    }),
    ...mapState(useNetworksStore, {
      networks: "networks",
      loadingNetwork: "loading",
    }),
  },
  methods: {
    ...mapActions(useContractsStore, [
      "fetchContracts",
      "getContracts",
      "emptyContracts",
    ]),
    ...mapActions(useSheetsStore, [
      "fetchSheets",
      "getSheets",
      "emptySheets",
      "syncSheets",
    ]),
    ...mapActions(useBuildingsStore, [
      "fetchBuildings",
      "getBuildings",
      "emptyBuildings",
      'syncBuildings',
    ]),
    ...mapActions(useInterventionsStore, [
      "fetchInterventions",
      "getInterventions",
      "syncInterventions",
      "emptyInterventions",
      "getNumberOfInterventionToSync"
    ]),
    ...mapActions(useToursStore, [
      "fetchTours",
      "getTours",
      "emptyTours",
    ]),
    ...mapActions(useTechnicalsStore, [
      "fetchTechnicals",
      "getTechnicals",
      "syncTechnicals",
      "emptyTechnicals",
    ]),
    ...mapActions(useNetworksStore, [
      "fetchNetworks",
      "getNetworks",
      "emptyNetworks",
    ]),
    ...mapActions(usePartsStore, [
      "fetchParts",
      "getParts",
      "emptyParts"
    ]),
    ...mapActions(useObservationsStore, [
      "fetchObservations",
      "getObservations",
      "emptyObservations",
    ]),
    ...mapActions(useProductsStore, [
      "fetchProducts",
      "getProducts",
      "emptyProducts",
    ]),
    ...mapActions(useTechniciansStore, [
      "fetchTechnicians",
      "getTechnicians",
      "emptyTechnicians",
    ]),
    ...mapActions(useTypesStore, [
      "fetchTypes",
      "getTypes",
      "emptyTypes",
    ]),
    fetchAll() {
      this.sync.loading = true
      this.sync.message = true
      Promise.all([
        this.fetchContracts(),
        this.fetchSheets(),
        this.fetchBuildings(),
        this.fetchInterventions(),
        this.fetchTours(),
        this.fetchTechnicals(),
        this.fetchNetworks(),
        this.fetchParts(),
        this.fetchObservations(),
        this.fetchProducts(),
        this.fetchTechnicians(),
        this.fetchTypes(),
      ]).then(() => {
        this.sync.loading = false
        this.sync.message = false
        localStorage.setItem('lastSync', moment().format('DD.MM.YYYY à HH:mm:ss'))
        this.lastSync = localStorage.getItem('lastSync')
      })
    },
    initAll() {
      this.getContracts();
      this.getSheets();
      this.getBuildings();
      this.getInterventions();
      this.getTours();
      this.getTechnicals();
      this.getNetworks();
      this.getParts();
      this.getObservations();
      this.getProducts();
      this.getTechnicians();
      this.getTypes();
    },
    setAll() {
      this.update.loading = true
      Promise.all([
        this.syncInterventions(),
        this.syncTechnicals(),
        this.syncBuildings(),
        this.syncSheets(),
      ]).then(() => {
        this.update.loading = false
        this.update.message = true
        this.interventionToSync = []
        this.buildingToSync = []
        this.sheetToSync = []
        setTimeout(() => {
          this.update.message = false
          // this.fetchAll()
        }, 2500);
      })
    },
    emptyAll() {
      this.emptyBuildings()
      this.emptyContracts()
      this.emptyInterventions()
      this.emptyNetworks()
      this.emptyObservations()
      this.emptyParts()
      this.emptyProducts()
      this.emptySheets()
      this.emptyTechnicals()
      this.emptyTechnicians()
      this.emptyTours()
      this.emptyTypes()
    },
    deleteDB() {
      local
        .delete()
        .then(() => {
          console.log("Database successfully deleted");
        })
        .catch((err) => {
          console.error("Could not delete database");
          console.log(err);
        })
        .finally(() => {
          local.open().then(() => {
            this.emptyAll()
          });
          this.dialog = false
          console.log("Database successfully created");
        });
    },
    countDataToSync() {
      this.interventionToSync = this.interventions.filter((intervention) => intervention.synchronized === 0)
      this.buildingToSync = this.buildings.filter((building) => building.synchronized === 0)
      this.sheetToSync = this.sheets.filter((sheet) => sheet.synchronized === 0)
    }
  },
}

</script>
