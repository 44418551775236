<template>
  <v-app :dark="!showMenu">
    <template v-if="showMenu">
      <v-navigation-drawer v-model="drawer" fixed app>
        <v-toolbar flat color="primary">
          <router-link
            :to="{ name: 'home' }"
            @click.native="drawer = false"
            class="logo-link"
          >

          </router-link>
        </v-toolbar>
        <v-divider></v-divider>
        <v-list>
          <template v-for="item in items">
            <v-list-item
              :key="item.title"
              :to="item.path"
              @click.native="drawer = false"
            >
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-divider></v-divider>
          <v-list-item @click="logout">
            <v-list-item-action>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Se déconnecter</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <p class="caption text-center">{{ userName }}</p>
      </v-navigation-drawer>
    </template>
    <v-app-bar app color="primary" dark v-if="showMenu">
      <v-row>
        <v-col cols="2">
          <v-app-bar-nav-icon
            @click.stop="drawer = !drawer"
          ></v-app-bar-nav-icon>
        </v-col>
        <v-col class="d-flex justify-space-around align-center">
          <img
            src="./assets/logo-detartrage-blanc.svg"
            alt="Logo"
            height="32px"
          />
        </v-col>
        
        <v-col cols="2" class="d-flex justify-end">
          <sheet-menu v-if="$route.name === 'sheet'"></sheet-menu>
        </v-col>

      </v-row>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer>
      <v-flex text-center>
        Détartrage Services SA &copy; {{ currentYear }}
      </v-flex>
    </v-footer>
  </v-app>
</template>

<script>
import moment from "moment";
import { useUsersStore } from "@/store/modules/user";
import { mapActions, mapState } from "pinia";
import SheetMenu from "@/components/menu/Sheet";

export default {
  name: "App",
  components: {
    SheetMenu,
  },
  data() {
    return {
      drawer: null,
      items: [
        {
          icon: "mdi-arrow-decision",
          title: "Tournées",
          path: "/tours",
        },
        // {
        //   icon: "mdi-office-building",
        //   title: "Immeubles",
        //   path: "/buildings",
        // },
        // {
        //   icon: "mdi-file-sign",
        //   title: "Contrats",
        //   path: "/contracts",
        // },
        {
          icon: "mdi-file-multiple-outline",
          title: "Fiches",
          path: "/sheets",
        },

        {
          icon: "mdi-autorenew",
          title: "Synchronisation",
          path: "/sync",
        },
      ],
    };
  },
  mounted() {
    this.getUser();
  },
  computed: {
    ...mapState(useUsersStore, {
      user: "user",
    }),
    showMenu() {
      let hide = ["login", "password_forgot", "password_reset"];
      if (hide.includes(this.$route.name)) {
        return false;
      }

      return true;
    },
    userName() {
      return this.user.full_name;
    },
    currentYear() {
      return moment().format("YYYY");
    },
  },
  methods: {
    ...mapActions(useUsersStore, ["getUser"]),
    logout() {
      localStorage.removeItem("token");
      this.$router.push({
        name: "login",
      });
    },
  },
};
</script>
