<template>
  <v-container fill-height>
    <v-layout column align-center class="pt-4">
      <v-form class="login-form mb-3" ref="form">
        <v-text-field
          v-model="auth.email"
          label="E-mail"
          required
          :type="'email'"
        ></v-text-field>
        <v-text-field
          v-model="auth.password"
          label="Mot de passe"
          required
          :type="'password'"
        ></v-text-field>
        <v-text-field
          v-model="auth.password_confirmation"
          label="Confirmer le mot de passe"
          required
          :type="'password'"
        ></v-text-field>
      </v-form>
      <v-btn
        large
        color="primary"
        :loading="loading"
        :disabled="loading"
        @click="updatePassword()"
      >
        Envoyer
      </v-btn>
      <router-link
        :to="{ name: 'login' }"
        class="mt-3"
      >Se connecter</router-link>
    </v-layout>
  </v-container>

</template>

<script>

import Auth from '@/api/auth';

export default {
  data() {
    return {
      auth: {
        email: '',
        password: '',
        password_confirmation: '',
      },
      loading: false,
    }
  },
  computed: {
    token() {
      return this.$route.params.token
    },
  },
  methods: {
    updatePassword() {
      this.loading = true
      Auth
      .reset({
        email: this.auth.email,
        password: this.auth.password,
        password_confirmation: this.auth.password_confirmation,
        token: this.token,
      })
      .then(() => {
        this.loading = false
      })
    }
  },
}

</script>

<style>

.login-form {
  width: 100%;
  max-width: 280px;
}

</style>
