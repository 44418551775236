import apiClient from '@/api/client';

export default {
  login(auth) {
    return apiClient.post('/login', auth);
  },
  forgot(auth) {
    return apiClient.post('/password/email', auth);
  },
  reset(auth) {
    return apiClient.post('/password/reset', auth);
  }
};
