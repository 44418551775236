import apiClient from '@/api/client';

export default {
  fetchInterventions() {
    return apiClient.get('/interventions')
  },
  fetchIntervention(id) {
    return apiClient.get('/interventions/' + id)
  },
  createIntervention(intervention) {
    return apiClient.post('/interventions', intervention)
  },
  updateIntervention(intervention) {
    return apiClient.post('/interventions/' + intervention.id, intervention)
  },
}
