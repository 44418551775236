<template>
  <v-list-item :to="{ name: 'tour', params: { tourId: tour.id } }">
    <v-list-item-content>
      <v-list-item-title>
        {{ tour.name }} - {{ countSheet }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ technician ? technician.full_name : "-" }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>

import { mapActions, mapState } from 'pinia';
import { useTechniciansStore } from '@/store/modules/technician';

export default {
  props: [
    'tour'
  ],
  computed: {
    ...mapState(useTechniciansStore, [
      'getTechnicianById'
    ]),
    technician() {
      return this.getTechnicianById(this.tour.technician_id);
    },
    countSheet() {
      if (this.tour.count && this.tour.count > 0) {
        return this.tour.count + ' fiches'
      }

      return this.tour.count + ' fiche'
    },
  },
  methods: {
    ...mapActions(useTechniciansStore, [
      'getTechnician'
    ]),
  },
}

</script>
