<template>
  <div>
    <v-container v-if="tour">
      <div class="headline mt-1">{{ tour.name }}</div>
    </v-container>
    <template v-if="sheets && sheets.length">
      <sheet-list :sheets="sheets" :sheetsId="tour.sheets"></sheet-list>
    </template>
    <v-bottom-navigation :fixed="true">
      <v-btn value="recent" :to="{ name: 'tours' }">
        <span>Tournées</span>
        <v-icon>mdi-arrow-decision</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>

import { mapActions, mapState } from "pinia";
import SheetList from "@/components/sheet/List";
import { useSheetsStore } from "@/store/modules/sheet";
import { useTechniciansStore } from "@/store/modules/technician";
import { useToursStore } from "@/store/modules/tour";
import { useBuildingsStore } from "@/store/modules/building";

export default {
  components: {
    SheetList,
  },
  mounted() {
    this.getSheets();
    this.getBuildings();
  },
  computed: {
    ...mapState(useSheetsStore, [
      'getSheetsByTourId',
      'getSheetById'
    ]),
    ...mapState(useTechniciansStore, [
      'getTechnicianById',
    ]),
    ...mapState(useToursStore, [
      'getTourById',
    ]),
    tourId() {
      return parseInt(this.$route.params.tourId);
    },
    tour() {
      return this.getTourById(this.tourId);
    },
    sheets() {
      if (this.tour) {
        return this.tour.sheets.map((sheetId) => {
          return this.getSheetById(sheetId)
        });
      }

      return false
    },
    technician() {
      if (this.tour) {
        return this.getTechnicianById(this.tour.technician_id);
      }

      return false;
    },
  },
  methods: {
    ...mapActions(useSheetsStore, [
      'getSheets',
    ]),
    ...mapActions(useBuildingsStore, [
      'getBuildings',
    ]),
  },
}

</script>