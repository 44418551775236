var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th'),_c('th',{staticClass:"text-center"},[_vm._v("Date")]),(_vm.typeId == 1)?_c('th',{staticClass:"text-center"},[_vm._v("Dureté (°F)")]):_vm._e(),_c('th',{staticClass:"text-center"},[_vm._v("Index du compteur (m3)")]),_c('th',{staticClass:"text-center"},[_vm._v("Consommation d'eau (m3)")]),(_vm.typeId != 1)?_c('th',{staticClass:"text-center"},[_vm._v("Consommation réelle de produit (cm3/m3)")]):_vm._e(),_c('th',{staticClass:"text-center"},[_vm._v("Quantité livrée (kg)")]),(_vm.typeId == 1)?_c('th',{staticClass:"text-center"},[_vm._v("Quantité insérée (kg)")]):_vm._e(),_c('th',{staticClass:"text-center"},[_vm._v("Quantité restante (kg)")]),_c('th',{staticClass:"text-left",staticStyle:{"min-width":"250px !important"}},[_vm._v("Observations")]),_c('th',{staticClass:"text-left",staticStyle:{"min-width":"250px !important"}},[_vm._v("Pièces remplacées")]),_c('th',{staticClass:"text-left"},[_vm._v("Médias")])])]),_c('tbody',[_vm._l((_vm.interventions),function(intervention,index){return [_c('tr',{key:intervention.id},[_c('td',{staticClass:"text-center"},[(!intervention.synchronized)?_c('v-btn',{attrs:{"color":"red","icon":""},on:{"click":function($event){return _vm.removeIntervention(intervention.id)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1):_vm._e(),(!intervention.synchronized)?_c('v-btn',{attrs:{"color":"blue","icon":"","to":{
                name: 'intervention_update',
                params: {
                  typeId: _vm.typeId,
                  sheetId: _vm.sheetId,
                  interventionId: intervention.id,
                  sheetsId: _vm.sheetsId,
                },
              }}},[_c('v-icon',[_vm._v("mdi-pencil-box-outline")])],1):_vm._e()],1),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(intervention.intervention_date)+" ")]),(_vm.typeId == 1)?_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(intervention.hardness)+" ")]):_vm._e(),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(intervention.meter_index))]),_c('td',{staticClass:"text-center"},[(intervention.synchronized)?[_vm._v(" "+_vm._s(intervention.consumption)+" ")]:[_vm._v(" "+_vm._s(_vm.getConsumption(intervention, index))+" ")]],2),(_vm.typeId != 1)?_c('td',{staticClass:"text-center"},[(intervention.synchronized)?[_vm._v(" "+_vm._s(intervention.real_product_consumption)+" ")]:[_vm._v(" "+_vm._s(_vm.getRealProductConsumption(intervention, index))+" ")]],2):_vm._e(),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(intervention.delivered_quantity))]),(_vm.typeId == 1)?_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(intervention.inserted_quantity)+" ")]):_vm._e(),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(intervention.remaining_quantity))]),_c('td',[_vm._v(_vm._s(intervention.observation))]),_c('td',[_vm._v(_vm._s(intervention.replaced_parts))]),_c('td',[(
                intervention.images.length !=
                intervention.images.filter((res) => res.toDelete == true)
                  .length
              )?_c('v-btn',{attrs:{"color":"primary","to":{
                name: 'intervention_images',
                params: {
                  interventionId: intervention.id,
                  sheetsId: _vm.sheetsId,
                },
              },"small":"","depressed":""}},[_vm._v(" Images ")]):_vm._e()],1)])]})],2)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }