<template>
  <div>
    <v-container>
      <div class="headline mt-1" v-if="building">{{ building.name }}</div>
    </v-container>
    <v-list dense>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content> Technicien </v-list-item-content>
        <v-list-item-content class="justify-end" v-if="technician">
          {{ technician.first_name }} {{ technician.last_name }}
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item
        :to="{
          name: 'building',
          params: { buildingId: contract.building_id },
        }"
        v-if="contract && building"
      >
        <v-list-item-content> Immeuble </v-list-item-content>
        <v-list-item-content class="justify-end">
          {{ building.name }}
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content> Numéro </v-list-item-content>
        <v-list-item-content class="justify-end">
          {{ contract ? "N°" + contract.number : "" }}
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content> Localité </v-list-item-content>
        <v-list-item-content class="justify-end" v-if="building">
          {{ building.zip }} {{ building.locality }}
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
    </v-list>
    <v-container v-if="sheets.length">
      <div class="text-h6 mt-2">Fiches</div>
    </v-container>
    <sheet-list :sheets="sheets" :sheetsId="sheetsId"></sheet-list>
    <v-bottom-navigation :fixed="true">
      <v-btn value="recent" :to="{ name: 'contracts' }">
        <span>Contrats</span>
        <v-icon>mdi-file-sign</v-icon>
      </v-btn>
      <v-btn
        value="recent"
        :to="{
          name: 'technical_update',
          params: {
            typeId: type.id,
            technicalId: technical.id
          },
        }"
        v-if="
          type && technical != undefined && !technical.hasOwnProperty('deleted')
        "
      >
        <span>Informations techniques</span>
        <v-icon>mdi-wrench</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { useContractsStore } from "@/store/modules/contract";
import { useTypesStore } from "@/store/modules/type";
import { useTechniciansStore } from "@/store/modules/technician";
import { useBuildingsStore } from "@/store/modules/building";
import { useNetworksStore } from "@/store/modules/network";
import { useTechnicalsStore } from "@/store/modules/technical";
import { useSheetsStore } from "@/store/modules/sheet";
import SheetList from "@/components/sheet/List";

export default {
  data() {
    return {};
  },
  components: {
    "sheet-list": SheetList,
  },
  computed: {
    ...mapState(useContractsStore, ["getContractById"]),
    ...mapState(useTypesStore, ["getTypeById"]),
    ...mapState(useTechniciansStore, ["getTechnicianById"]),
    ...mapState(useBuildingsStore, ["getBuildingById"]),
    ...mapState(useNetworksStore, ["getNetworkById"]),
    ...mapState(useSheetsStore, ["getSheetsByContractId"]),
    ...mapState(useTechnicalsStore, ["getTechnicalsByContractId"]),
    contractId() {
      return parseInt(this.$route.params.contractId);
    },
    sheetsId() {
      return this.$route.params.sheetsId;
    },
    contract() {
      return this.getContractById(this.contractId);
    },
    building() {
      if (this.contract) {
        return this.getBuildingById(this.contract.building_id);
      }

      return false;
    },
    type() {
      if (this.contract) {
        return this.getTypeById(this.contract.type_id);
      }

      return false;
    },
    technician() {
      if (this.contract) {
        return this.getTechnicianById(this.contract.technician_id);
      }

      return false;
    },
    network() {
      if (this.contract) {
        return this.getNetworkById(this.contract.network_id);
      }

      return false;
    },
    technical() {
      if (this.contract) {
        return this.getTechnicalsByContractId(this.contract.id);
      }

      return false;
    },
    sheets() {
      if (this.contract) {
        return this.getSheetsByContractId(this.contract.id);
      }

      return false;
    },
  },
};
</script>
