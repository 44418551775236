<template>
  <v-container>
    <v-text-field
      label="Marque pompe"
      type="text"
      v-model="pump_brand"
      v-on:change="changedLabel"
    >
    </v-text-field>
    <v-text-field
      label="Litrage bac"
      type="number"
      v-model="vat_capacity"
      v-on:change="changedLabel"
    >
    </v-text-field>
    <v-text-field
      label="Règlage de la pompe"
      type="text"
      v-model="pump_settings"
      v-on:change="changedLabel"
    >
    </v-text-field>
    <v-text-field
      label="Nombre d'impulsions / litre"
      type="number"
      v-model="impulse_litter"
      v-on:change="changedLabel"
    >
    </v-text-field>
    <v-text-field
      label="Code pompe"
      type="text"
      v-model="pump_code"
      v-on:change="changedLabel"
    >
    </v-text-field>
    <v-text-field
      label="Contact du concièrge"
      type="text"
      v-model="caretaker_contact"
      v-on:change="changedLabel"
    ></v-text-field>
    <v-text-field
      label="Type de clé"
      type="text"
      v-model="vat_key_type"
      v-on:change="changedLabel"
    ></v-text-field>
  </v-container>
</template>

<script>

export default {
  props: [
    'technicals',
  ],
  data() {
    return {
      pump_brand: this.technicals.pump_brand,
      vat_capacity: this.technicals.vat_capacity,
      pump_settings: this.technicals.pump_settings,
      impulse_litter: this.technicals.impulse_litter,
      pump_code: this.technicals.pump_code,
      caretaker_contact: this.technicals.caretaker_contact,
      vat_key_type: this.technicals.vat_key_type,
    }
  },
  mounted () {
    this.$emit('technicalInformationsFilm', { 
      'pump_brand' : this.pump_brand,
      'vat_capacity' : this.vat_capacity,
      'pump_settings' : this.pump_settings,
      'impulse_litter' : this.impulse_litter,
      'pump_code' : this.pump_code, 
      'caretaker_contact': this.caretaker_contact,
      'vat_key_type': this.vat_key_type,
    })
  },
  methods: {
    changedLabel () {
      this.$emit('technicalInformationsFilm', { 
        'pump_brand' : this.pump_brand,
        'vat_capacity' : this.vat_capacity,
        'pump_settings' : this.pump_settings,
        'impulse_litter' : this.impulse_litter,
        'pump_code' : this.pump_code, 
        'caretaker_contact': this.caretaker_contact,
        'vat_key_type': this.vat_key_type,
      })
    },
  }
}

</script>
