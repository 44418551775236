import productApi from '@/api/product'
import local from '@/database/local.js'
import { defineStore } from 'pinia';

export const useProductsStore = defineStore('products', {
  state: () => ({
    loading: false,
    products: [],
  }),
  actions: {
    emptyProducts() {
      this.products = []
    },
    getProducts() {
      this.loading = true
      local
        .products
        .toArray()
        .then(products => {
          this.products = products
          this.loading = false
        });
    },
    fetchProducts() {
      this.loading = true
      return productApi
        .fetchProducts()
        .then((response) => {
          local.products.bulkPut(response.data.products)
          this.loading = false
        })
        .then(() => {
          this.getProducts()
        })
    },
  },
  getters: {
    getProductById: (state) => {
      return (productId) => state.products.find((product) => product.id === productId)
    },
  },
})
