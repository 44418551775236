import Vue from "vue";
import App from "./App.vue";
//import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { createPinia, PiniaVuePlugin } from 'pinia'
import './registerServiceWorker'
import VueOffline from 'vue-offline'

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import resources from "./resources";

if (process.env.VUE_APP_SENTRY_ENABLE == true) {
  Sentry.init({
    Vue,
    dsn: "https://49c8fe7babf94f848e502e639db8afa3@errors.devfactory.ch/11",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "my-site-url.com", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

Vue.config.productionTip = false;
Vue.use(PiniaVuePlugin)
Vue.use(VueOffline)
const pinia = createPinia()

const app = new Vue({
  pinia,
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");

resources.get();

router.onReady(() => {
  app.$mount("#app");
});

router.beforeEach((to, from, next) => {
  let openRoute = [
    'login',
    'password_forgot',
    'password_reset',
  ]
  if (!openRoute.includes(to.name) && !isAuthenticated()) {
    next({ name: 'login' })
  } else {
    next();
  }
});

function isAuthenticated() {
  const token = localStorage.getItem('token');
  if (token === null) {
    return false;
  }

  if (token === 'undefined') {
    return false;
  }

  return true;
}
