import interventionApi from '@/api/intervention'
import local from '@/database/local.js'
import { defineStore } from 'pinia'

export const useInterventionsStore = defineStore('interventions', {
  state: () => ({
    loading: false,
    interventions: [],
    intervention: false,
  }),
  actions: {
    emptyInterventions() {
      this.interventions = []
    },
    getInterventions() {
      this.loading = true
      local
        .interventions
        .toArray()
        .then(interventions => {
          this.interventions = interventions
          this.loading = false
        });
    },
    fetchInterventions() {
      this.loading = true
      return interventionApi
        .fetchInterventions()
        .then((response) => {
          local.interventions.bulkPut(response.data)
          this.loading = false
        })
        .then(() => {
          this.getInterventions()
        })
    },
    createIntervention(intervention) {
      return local
        .interventions
        .add(intervention)
    },
    removeIntervention(interventionId) {
      local
        .interventions
        .where('id')
        .equals(interventionId)
        .delete()

      this.getInterventions()
    },
    updateIntervention(intervention) {
      return local
        .interventions
        .update(intervention.id, intervention)
    },
    destroyImages(ids) {
      local
        .interventions
        .where('id')
        .equals(ids.interventionId)
        .modify(res => {
          if (ids.imgId == undefined) {
            res.images.splice(ids.index, 1)
          } else {
            res.images[ids.index].toDelete = true
            res.synchronized = 0
            res.updated = 1
          }
          this.intervention = res
        }).then(() => {
          this.getInterventions()
        })
    },
    syncInterventions() {
      this.loading = true
      return local
        .interventions
        .where('synchronized')
        .equals(0)
        .toArray()
        .then(interventions => {
          interventions.forEach((intervention) => {
            if (!intervention.updated) {
              local
                .interventions
                .where('id')
                .equals(intervention.id)
                .delete()
              interventionApi
                .createIntervention(intervention)
                .then(response => {
                  local.interventions.put(response.data.intervention)
                  this.getInterventions()
                })
            } else {
              interventionApi
                .updateIntervention(intervention)
                .then(response => {
                  local.interventions.put(response.data.intervention)
                  this.getInterventions()
                })
            }
          })
        })
        .then(() => {
          this.loading = false
        })
    },
    getNumberOfInterventionToSync() {
      return local
      .interventions
      .where('synchronized')
      .equals(0)
      .toArray()
    }
  },
  getters: {
    getInterventionById: (state) => {
      return (interventionId) => state.interventions.find((intervention) => intervention.id === interventionId)
    },
    getInterventionsBySheetId: (state) => {
      return (sheetId) => state.interventions
        .filter((intervention) => intervention.sheet_id === sheetId)
        .sort((a, b) => a.date_timestamp - b.date_timestamp)
    },
  },
})
