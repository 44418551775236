<template>
  <div>
    <v-container>
      <div class="headline mt-1">Contrats</div>
      <v-text-field
        placeholder="Rechercher"
        append-icon="mdi-magnify"
        v-model="search"
        v-on:keyup="getSearch"
      ></v-text-field>
    </v-container>
    <template v-if="hasContracts">
      <contract-list
        :contracts="currentContracts"
      ></contract-list>
    </template>
    <template v-else>
      <v-container>
        <v-alert
          border="left"
          type="warning"
        >
          Aucune contrat dans la base de données.
        </v-alert>
      </v-container>
    </template>
    <v-container v-if="hasContracts">
      <v-pagination
        v-model="page"
        :length="currentPage"
        :total-visible="5"
      ></v-pagination>
    </v-container>
  </div>
</template>

<script>

import ContractList from "@/components/contract/List";
import { mapActions, mapState } from "pinia";
import { useContractsStore } from "@/store/modules/contract";
import { useBuildingsStore } from "@/store/modules/building";

export default {
  data() {
    return {
      search: "",
      page: 1,
      perPage: 50,
      timer: 0,
    };
  },
  components: {
    ContractList,
  },
  computed: {
    ...mapState(useContractsStore, ["contracts"]),
    currentContracts() {
      return this.contracts.slice(
        (this.page - 1) * this.perPage,
        this.page * this.perPage
      );
    },
    currentPage() {
      return Math.ceil(this.contracts.length / this.perPage);
    },
    hasContracts() {
      if (this.contracts && this.contracts.length) {
        return true
      }

      return false
    }
  },
  mounted() {
    this.getContracts();
    this.getBuildings();
  },
  methods: {
    ...mapActions(useContractsStore, ["getContracts", "searchContracts"]),
    ...mapActions(useBuildingsStore, ["getBuildings"]),
    getSearch() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }

      this.timer = setTimeout(() => {
        this.searchContracts(this.search);
      }, 200);
    },
  },
};
</script>
