import apiClient from "@/api/client";

export default {
  fetchBuildings() {
    return apiClient.get("/buildings");
  },
  fetchBuilding(id) {
    return apiClient.get("/buildings/" + id);
  },
  updateBuilding(building) {
    return apiClient.put('/buildings/' + building.id, building)
  },
};
