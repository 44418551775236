<template>
  <v-container>
    <date @update-date="update"></date>
    <template v-if="typeId == 1">
      <form-softener
        @softenerIntervention="softenerIntervention"
        :replaced-parts="parts"
      ></form-softener>
    </template>
    <template v-else>
      <form-film-treatment
        @filmIntervention="filmIntervention"
        :replaced-parts="parts"
        :observations="observations"
      ></form-film-treatment>
    </template>

    <image-uploader
      className="fileInput"
      capture="environment"
      :debug="1"
      :maxWidth="1024"
      :maxHeight="768"
      :autoRotate="true"
      outputFormat="verbose"
      :preview="false"
      @input="setImage"
    >
      <label for="fileInput" slot="upload-label">
        <span
          class="v-btn v-btn--block v-btn--has-bg theme--light v-size--default"
        >
          <v-icon class="mr-2">mdi-camera</v-icon> Ajouter une photo
        </span>
      </label>
    </image-uploader>
    <template v-if="images.length">
      <div class="my-2">{{ images.length }} image(s)</div>
    </template>
    <template v-for="(img, index) in images">
      <img :key="index" height="50px" class="mr-2" v-bind:src="img.dataUrl" />
    </template>
    <v-layout class="mt-2 mb-4" justify-end>
      <v-btn depressed class="mr-2" @click="cancel()">
        Annuler
      </v-btn>
      <v-btn depressed color="primary" @click="store()">
        Envoyer
      </v-btn>
    </v-layout>
  </v-container>
</template>

<script>

import Date from "@/components/date/Date"
import FormSoftener from "@/components/intervention/FormSoftener"
import FormFilmTreatment from "@/components/intervention/FormFilmTreatment"
import ImageUploader from "vue-image-upload-resize"
import { mapActions, mapState } from "pinia"
import { useInterventionsStore } from "@/store/modules/intervention"
import { usePartsStore } from "@/store/modules/part"
import { useObservationsStore } from "@/store/modules/observation"
import moment from 'moment'

export default {
  data() {
    return {
      intervention: {},
      loading: false,
      date: '',
      hasImage: false,
      images: [],
    };
  },
  components: {
    Date,
    FormSoftener,
    FormFilmTreatment,
    ImageUploader,
  },
  computed: {
    ...mapState(usePartsStore, [
      'parts',
    ]),
    ...mapState(useObservationsStore, [
      'observations',
    ]),
    sheetId() {
      return this.$route.params.sheetId;
    },
    typeId() {
      return this.$route.params.typeId;
    },
    sheetsId() {
      return this.$route.params.sheetsId;
    }
  },
  methods: {
    ...mapActions(useInterventionsStore, [
      'createIntervention',
    ]),
    cancel() {
      this
      .$router
      .push({
        name: 'sheet',
        params: {
          sheetId: this.sheetId,
          sheetsId: this.sheetsId,
        },
      });
    },
    store() {
      this.loading = true;
      this.intervention.intervention_date = this.date
      this.intervention.date_timestamp = moment(this.date).unix()
      this.intervention.sheet_id = this.sheetId
      this.intervention.synchronized = 0
      this.intervention.images = this.images

      this.createIntervention(this.intervention).then(() => {
        this.loading = false;
        this
        .$router
        .push({
          name: 'sheet',
          params: {
            sheetId: this.sheetId,
            sheetsId: this.sheetsId,
          },
        });
      });
    },
    update(date) {
      this.date = date;
    },
    softenerIntervention(intervention) {
      this.intervention = intervention;
    },
    filmIntervention(intervention) {
      this.intervention = intervention;
    },
    setImage(output) {
      this.images.push(output);
    },
  },
}

</script>

<style>

#fileInput {
  display: none;
}

</style>
