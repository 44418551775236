<template>
  <v-container>

  </v-container>
</template>

<script>

export default {
  data() {
    return {

    }
  },
  computed: {
    contractId() {
      return this.$route.params.contractId
    }
  },
  methods: {

  }
}

</script>
