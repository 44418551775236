import partApi from '@/api/part'
import local from '@/database/local.js'
import { defineStore } from 'pinia';

export const usePartsStore = defineStore('parts', {
  state: () => ({
    loading: false,
    parts: [],
  }),
  actions: {
    emptyParts() {
      this.parts = []
    },
    getParts() {
      this.loading = true
      local
        .parts
        .toArray()
        .then(parts => {
          console.log(parts)
          this.parts = parts
          this.loading = false
        });
    },
    fetchParts() {
      this.loading = true
      return partApi
        .fetchParts()
        .then((response) => {
          local.parts.bulkPut(response.data.parts)
          this.loading = false
        })
        .then(() => {
          this.getParts()
        })
    },
  },
  getters: {
    getPartById: (state) => {
      return (partId) => state.parts.find((part) => part.id === partId)
    },
  },
})
