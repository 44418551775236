import contractApi from "@/api/contract";
import local from "@/database/local.js";
import { defineStore } from "pinia";

export const useContractsStore = defineStore('contracts', {
  state: () => ({
    loading: false,
    contracts: [],
  }),
  actions: {
    emptyContracts() {
      this.contracts = []
    },
    getContracts() {
      this.loading = true
      local
        .contracts
        .toArray()
        .then(contracts => {
          this.contracts = contracts.sort((a, b) => a.building_name?.localeCompare(b.building_name))
          this.loading = false
        })
    },
    searchContracts(search) {
      local
        .contracts
        .filter((contract) => {
          return contract.building_name?.toLowerCase().indexOf(search.toLowerCase()) >= 0
        })
        .toArray()
        .then(contracts => {
          this.contracts = contracts
          this.loading = false
        })
    },
    fetchContracts() {
      this.loading = true
      return contractApi
        .fetchContracts()
        .then((response) => {
          local.contracts.bulkPut(response.data)
          this.loading = false
        })
        .then(() => {
          this.getContracts()
        });
    },
  },
  getters: {
    getContractById: (state) => {
      return (contractId) => state.contracts.find((contract) => contract.id === contractId)
    },
    getContractsByTourId: (state) => {
      return (tourId) => state.contracts.filter((contract) => contract.tours.find((contractTourId) => contractTourId === tourId))
    },
    getContractsByBuildingId: (state) => {
      return (buildingId) => state.contracts.filter((contract) => contract.building_id === buildingId)
    }
  },
})
