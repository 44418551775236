<template>
  <v-list-item :to="{ name: 'sheet', params: { sheetId: sheet.id, sheetsId : sheetsId } }">
    <v-list-item-content>
      <v-list-item-title v-if="building" class="pr-3">
        <p class="mb-0">
          {{ sheet.name }} - {{ building.name }}
        </p>
      </v-list-item-title>
      <v-list-item-subtitle>
        <template v-if="type">{{ type.name }}</template>
        <sheet-network-type :sheet="sheet"></sheet-network-type>
        <template v-if="sheet">
          <p class="mb-0">
            Produit : {{ sheet.product_name }}
          </p>
        </template>
        <template v-if="sheet.last_intervention_remaining_quantity">
          <p class="mb-0">
            Quantité restante : {{ sheet.last_intervention_remaining_quantity }}
          </p>
        </template>
        <template v-if="sheet.last_intervention_date">
          <p class="mb-0">
            Dernier passage : {{ sheet.last_intervention_date }}
          </p>
        </template>
        <template v-if="sheet.comment">
          <p class="mb-0">
            Commentaire : {{ sheet.comment }}
          </p>
        </template>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>

import moment from 'moment';
import { mapState } from "pinia";
import { useBuildingsStore } from "@/store/modules/building";
import { useContractsStore } from "@/store/modules/contract";
import { useNetworksStore } from "@/store/modules/network";
import { useTypesStore } from "@/store/modules/type";
import { useTechniciansStore } from "@/store/modules/technician";
import SheetNetworkType from '@/components/sheet/NetworkType.vue';

export default {
  components: {
    SheetNetworkType
  },
  data() {
    return {
      moment: moment
    };
  },
  props: [
    'sheet',
    'sheetsId'
  ],
  computed: {
    ...mapState(useBuildingsStore, ["getBuildingById"]),
    ...mapState(useContractsStore, ["getContractById"]),
    ...mapState(useNetworksStore, ["getNetworkById"]),
    ...mapState(useTypesStore, ["getTypeById"]),
    ...mapState(useTechniciansStore, ["getTechnicianById"]),
    building() {
      if (this.contract) {
        return this.getBuildingById(this.contract.building_id);
      }

      return false;
    },
    contract() {
      if (this.sheet) {
        return this.getContractById(this.sheet.contract_id);
      }

      return false;
    },
    network() {
      if (this.sheet) {
        return this.getNetworkById(this.sheet.network_id);
      }

      return false;
    },
    type() {
      if (this.sheet) {
        return this.getTypeById(this.sheet.type_id);
      }

      return false;
    },
  },
}

</script>
