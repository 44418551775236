import technicalApi from '@/api/technical'
import local from '@/database/local.js'
import { defineStore } from 'pinia';

export const useTechnicalsStore = defineStore('technicals', {
  state: () => ({
    loading: false,
    technical: false,
    technicals: [],
  }),
  actions: {
    emptyTechnicals() {
      this.technicals = []
    },
    setTechnical(technical) {
      this.technical = technical
    },
    getTechnicals() {
      this.loading = true
      local
        .technicals
        .toArray()
        .then(technicals => {
          this.technicals = technicals
          this.loading = false
        });
    },
    fetchTechnicals() {
      this.loading = true
      return technicalApi
        .fetchTechnicals()
        .then((response) => {
          local.technicals.bulkPut(response.data.technicals)
          this.loading = false
        })
        .then(() => {
          this.getTechnicals()
        })
    },
    createTechnicals(technicals) {
      this.loading = true
      local
        .technicals
        .add(technicals)
        .then(() => {
          this.getTechnicals()
          this.loading = false
        })
    },
    destroyTechnical(technicalId) {
      local
        .technicals
        .where('id')
        .equals(technicalId)
        .modify(res => {
          res.synchronized = 0
          res.deleted = 1
          this.technical = res
        }).then(() => {
          this.getTechnicals()
        })
    },
    updateTechnicals(technical) {
      this.loading = true
      if (!technical) {
        return;
      }

      return local.technicals.where("id").equals(technical.id).modify(res => {
        res.pump_brand = technical.pump_brand
        res.vat_capacity = technical.vat_capacity
        res.pump_settings = technical.pump_settings
        res.impulse_litter = technical.impulse_litter
        res.pump_code = technical.pump_code
        res.caretaker_contact = technical.caretaker_contact
        res.vat_key_type = technical.vat_key_type
        res.softener_type = technical.softener_type
        res.synchronized = 0
        this.technical = res
      }).then(() => {
        this.getTechnicals()
        this.loading = false
      });
    },
    syncTechnicals() {
      this.loading = true
      return local.technicals.where("synchronized").equals(0).toArray().then(technicals => {
        technicals.forEach((technical) => {
          if (!technical.deleted) {
            technicalApi.updateTechnical(technical).then(response => {
              local.technicals.put(response.data.technical)
              this.getTechnicals()
            }).catch((err) => {
              console.log(err)
            })
          } else {
            local.technicals.where("id").equals(technical.id).delete()
            technicalApi.deleteTechnical(technical.id).then(() => {
              this.getTechnicals()
            })
          }
        })
      })
        .then(() => {
          this.loading = false
        })
    }
  },
  getters: {
    getTechnicalById: (state) => {
      return (technicalId) => state.technicals.find((technical) => technical.id === technicalId)
    },
    getTechnicalsBySheetId: (state) => {
      return (sheetId) => state.technicals.find((technical) => technical.sheet_id === sheetId)
    },
  },
})
