<template>
  <div>
    <v-container>
      <div class="headline mt-1">Tournées</div>
      <v-text-field
        placeholder="Rechercher"
        append-icon="mdi-magnify"
        v-model="search"
        v-on:keyup="getSearch"
      >
      </v-text-field>
    </v-container>
    <template v-if="hasTours">
      <tour-list :tours="currentTours"></tour-list>
    </template>
    <template v-else>
      <v-container>
        <v-alert
          border="left"
          type="warning"
        >
          Aucune tournée dans la base de données.
        </v-alert>
      </v-container>
    </template>
    <v-container v-if="hasTours">
      <v-pagination
        v-model="page"
        :length="currentPage"
        :total-visible="5"
      ></v-pagination>
    </v-container>
  </div>
</template>

<script>

import TourList from "@/components/tour/List";
import { mapState, mapActions } from "pinia";
import { useToursStore } from "@/store/modules/tour";

export default {
  data() {
    return {
      search: "",
      page: 1,
      perPage: 50,
      timer: 0,
    };
  },
  components: {
    "tour-list": TourList,
  },
  computed: {
    ...mapState(useToursStore, ["getTechnicianTours"]),
    tours() {
      return this.getTechnicianTours(localStorage.getItem("user_id"));
    },
    currentTours() {
      return this.tours.slice(
        (this.page - 1) * this.perPage,
        this.page * this.perPage
      );
    },
    currentPage() {
      return Math.ceil(this.tours.length / this.perPage);
    },
    hasTours() {
      if (this.tours && this.tours.length) {
        return true
      }

      return false
    }
  },
  mounted() {
    this.getTours();
  },
  methods: {
    ...mapActions(useToursStore, [
      "getTours",
      "searchTours"
    ]),
    getSearch() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }

      this.timer = setTimeout(() => {
        this.searchTours(this.search);
      }, 200);
    },
  },
}

</script>
