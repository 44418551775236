<template>
  <span class="ml-2">
    <v-icon small :color="sheet.product_color">mdi-circle</v-icon>
  </span>
</template>

<script>

export default {
  props: [
    'sheet'
  ],
}

</script>