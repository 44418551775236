import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

import LoginPage from '@/pages/auth/Login';
import ForgotPage from '@/pages/auth/Forgot';
import ResetPage from '@/pages/auth/Reset';

import ContractsPage from '@/pages/contract/Contracts';
import ContractPage from '@/pages/contract/Contract';

import SheetsPage from '@/pages/sheet/Sheets';
import SheetPage from '@/pages/sheet/Sheet';
import CommentPage from '@/pages/sheet/Comment';

import BuildingsPage from '@/pages/building/Buildings';
import BuildingPage from '@/pages/building/Building';
import InfoPage from '@/pages/building/Info';

import InterventionsPage from '@/pages/intervention/Interventions';
import InterventionCreatePage from '@/pages/intervention/Create';
import InterventionUpdatePage from '@/pages/intervention/Update';
import InterventionManageImages from '@/pages/intervention/Images';

import TechnicalUpdatePage from '@/pages/technical/Update';

import SyncPage from '@/pages/sync/Sync';

import TestPage from '@/pages/Test';

import ToursPage from '@/pages/tour/Tours';
import TourPage from '@/pages/tour/Tour';

export default new VueRouter({
  routes: [{
    path: '/login',
    name: 'login',
    component: LoginPage,
  }, {
    path: '/password/email',
    name: 'password_forgot',
    component: ForgotPage,
  }, {
    path: '/password/reset/:token',
    name: 'password_reset',
    component: ResetPage,
  }, {
    path: '/',
    name: 'home',
    component: ToursPage,
    beforeEnter: (to, from, next) => {
      let token = localStorage.getItem('token')
      if (token === null) {
        next({ name: 'login' })
      }

      if (token === 'undefined') {
        next({ name: 'login' })
      }

      next()
    }
  }, {
    path: '/contracts',
    name: 'contracts',
    component: ContractsPage,
  }, {
    path: '/contract/:contractId',
    name: 'contract',
    component: ContractPage,
  }, {
    path: '/sheets',
    name: 'sheets',
    component: SheetsPage,
  }, {
    path: '/sheets/:sheetId',
    name: 'sheet',
    component: SheetPage,
  }, {
    path: '/sheets/:sheetId/comment/update',
    name: 'comment',
    component: CommentPage,
  }, {
    path: '/buildings',
    name: 'buildings',
    component: BuildingsPage,
  }, {
    path: '/building/:buildingId',
    name: 'building',
    component: BuildingPage,
  }, {
    path: '/building/:buildingId/info/create',
    name: 'info',
    component: InfoPage,
  }, {
    path: '/interventions/:sheetId',
    name: 'interventions',
    component: InterventionsPage,
  }, {
    path: '/intervention/create/:sheetId/:typeId',
    name: 'intervention_create',
    component: InterventionCreatePage,
  }, {
    path: '/intervention/:interventionId/update',
    name: 'intervention_update',
    component: InterventionUpdatePage,
  }, {
    path: '/technical/update/:sheetId',
    name: 'technical_update',
    component: TechnicalUpdatePage,
  }, {
    path: '/intervention/:interventionId/media',
    name: 'intervention_images',
    component: InterventionManageImages,
  }, {
    path: '/sync',
    name: 'sync',
    component: SyncPage,
  }, {
    path: '/test',
    name: 'test',
    component: TestPage,
  }, {
    path: '/tours',
    name: 'tours',
    component: ToursPage,
  }, {
    path: '/tours/:tourId',
    name: 'tour',
    component: TourPage,
  }],
});
