<template>
  <v-container>
    <v-text-field
      label="Type d'adoucisseur"
      type="text"
      v-model="softener_type"
      v-on:change="changedLabel"
    ></v-text-field>
  </v-container>
</template>

<script>

export default {
  props: [
    'technicals',
  ],
  data() {
    return {
      softener_type: this.technicals.softener_type
    }
  },
  mounted () {
    this.$emit('technicalInformationsSoftener', { 
      'softener_type': this.softener_type
    })
  },
  methods: {
    changedLabel () {
      this.$emit('technicalInformationsSoftener', { 
        'softener_type': this.softener_type
      })
    },
  }
}

</script>
