<template>
  <v-list>
    <template v-for="contract in contracts">
      <contract-list-tile
        :key="contract.id"
        :contract="contract"
      ></contract-list-tile>
      <v-divider :key="'divider-' + contract.id"></v-divider>
    </template>
  </v-list>
</template>

<script>
import ContractListTile from "@/components/contract/ListTile";

export default {
  props: ["contracts"],
  components: {
    "contract-list-tile": ContractListTile,
  },
};
</script>
