import { useBuildingsStore } from "@/store/modules/building";
import { useContractsStore } from "@/store/modules/contract";
import { useInterventionsStore } from "@/store/modules/intervention";
import { useToursStore } from "@/store/modules/tour";
import { useNetworksStore } from "@/store/modules/network";
import { useObservationsStore } from "@/store/modules/observation";
import { usePartsStore } from "@/store/modules/part";
import { useProductsStore } from "@/store/modules/product";
import { useSheetsStore } from "@/store/modules/sheet";
import { useTechnicalsStore } from "@/store/modules/technical";
import { useTechniciansStore } from "@/store/modules/technician";
import { useTypesStore } from "@/store/modules/type";

export default {
  get() {
    useBuildingsStore().getBuildings();
    useContractsStore().getContracts();
    useInterventionsStore().getInterventions();
    useToursStore().getTours();
    useNetworksStore().getNetworks();
    useObservationsStore().getObservations();
    usePartsStore().getParts();
    useProductsStore().getProducts();
    useTechnicalsStore().getTechnicals();
    useTechniciansStore().getTechnicians();
    useTypesStore().getTypes();
    useSheetsStore().getSheets();
  }
}
