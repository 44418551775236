import Dexie from 'dexie';

const local = new Dexie('detartrage');

local.version(1).stores({
  buildings: `&id, building_id, name, number, address, zip, locality, erp_number, is_group, group_number, info, synchronized`,
  interventions: `++id, sheet_id, technician_id, product_id, intervention_date, date_timestamp, delivered_quantity, inserted_quantity, remaining_quantity, hardness, meter_index, consumption, real_product_consumption, replaced_parts, observation, synchronized`,
  contracts: `&id, technician_id, building_id, building_name, need_delivery_note, contract_number, tours, count`,
  technicals: `++id, sheet_id, pump_brand, vat_capacity, pump_settings, impulse_litter, pump_code, caretaker_contact, vat_key_type, softener_type, synchronized`,
  technicians: `&id, first_name, last_name`,
  tours: `&id, name, contracts, technician_id, count`,
  types: `&id, name`,
  parts: `&id, name`,
  networks: `&id, name`,
  products: `&id, type_id, name`,
  observations: `&id, name`,
  sheets: `&id, name, technician_id, type_id, network_id, product_id, contract_id, comment, synchronized`,
});

export default local;
