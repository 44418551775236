import axios from "axios";

const token = localStorage.getItem("token");
let headers = {}

if (token) {
  headers = {
    Authorization: `Bearer ${token}`,
  }
}

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: headers,
});

export default apiClient;
