<template>
  <v-container fill-height>
    <v-layout align-center column>
      <v-form class="login-form">
        <v-text-field
          v-model="auth.email"
          label="E-mail"
          required
          :type="'email'"
        >
        </v-text-field>
      </v-form>
      <v-btn
        large
        color="primary"
        :loading="loading"
        :disabled="loading"
        @click="forgot()"
      >
        Envoyer
      </v-btn>
      <router-link
        :to="{ name: 'login' }"
        class="mt-4"
      >
        Annuler
      </router-link>
    </v-layout>
  </v-container>
</template>

<script>

import Auth from '@/api/auth';

export default {
  data() {
    return {
      auth: {
        email: '',
      },
      valid: false,
      loading: false,
    }
  },
  methods: {
    forgot() {
      this.loading = true
      Auth
      .forgot(this.auth)
      .then(() => {
        this.loading = false
      })
    },
  },
}

</script>

<style>

.login-form {
  width: 100%;
  max-width: 280px;
}

</style>
