import sheetApi from "@/api/sheet";
import local from "@/database/local.js";
import { defineStore } from "pinia";

export const useSheetsStore = defineStore('sheets', {
  state: () => ({
    loadingState: false,
    sheets: [],
  }),
  actions: {
    emptySheets() {
      this.sheets = []
    },
    getSheets() {
      local
        .sheets
        .toArray()
        .then((sheets) => {
          this.sheets = sheets
          this.loading = false
        })
    },
    searchSheets(search) {
      local
        .sheets
        .filter((sheet) => {
          return sheet.name?.toLowerCase().indexOf(search.toLowerCase()) >= 0 || sheet.building_name?.toLowerCase().indexOf(search.toLowerCase()) >= 0
        })
        .toArray()
        .then(sheets => {
          this.sheets = sheets
          this.loading = false
        })
    },
    fetchSheets() {
      this.loading = true
      return sheetApi
        .fetchSheets().then((response) => {
          local.sheets.bulkPut(response.data)
          this.loading = false
        })
        .then(() => {
          this.getSheets()
        });
    },
    syncSheets() {
      return local.sheets
        .filter(sheet => sheet.synchronized === 0)
        .toArray()
        .then(sheets => {
          sheets.forEach(sheet => {
            sheetApi
              .updateSheet(sheet)
              .then(response => {
                local.sheets.update(sheet.id, response.data.sheet)
              })
          })
        })
    },
    storeComment(sheetId, comment) {
      local.sheets
        .update(sheetId, {
          'comment': comment,
          'synchronized': 0,
        })
    }
  },
  getters: {
    getSheetById: (state) => (id) => {
      return state.sheets.find((sheet) => sheet.id === id);
    },
    getSheetsByContractId: (state) => {
      return (contractId) => state.sheets.filter((sheet) => sheet.contract_id === contractId)
    }
  },
})
