<template>
  <v-container>
    <form-informations-softener
      @technicalInformationsSoftener="technicalInformationsSoftener"
      :technicals="technical"
      v-if="typeId == 1"
    ></form-informations-softener>
    <form-informations-film
      @technicalInformationsFilm="technicalInformationsFilm"
      :technicals="technical"
      v-else
    ></form-informations-film>
    <v-layout class="mt-2 mb-4" justify-start>
      <v-btn color="error" depressed class="ml-2" @click="destroy()">
        Supprimer
      </v-btn>
    </v-layout>
    <v-layout class="mt-2 mb-4" justify-end>
      <v-btn depressed class="mr-2" @click="cancel()"> Annuler </v-btn>
      <v-btn depressed class="mr-2" color="primary" @click="store()">
        Sauvegarder
      </v-btn>
    </v-layout>
  </v-container>
</template>

<script>
import FormInformationsFilm from "@/components/technical/FormInformationsFilm";
import FormInformationsSoftener from "@/components/technical/FormInformationsSoftener";
import { mapActions, mapState } from "pinia";
import { useTechnicalsStore } from "@/store/modules/technical";

export default {
  data() {
    return {
      updateTechnical: {},
      loading: false,
    };
  },
  components: {
    FormInformationsFilm,
    FormInformationsSoftener,
  },
  computed: {
    ...mapState(useTechnicalsStore, ["technical", "getTechnicalById"]),
    sheetId() {
      return this.$route.params.sheetId;
    },
    technicalId() {
      return this.$route.params.technicalId;
    },
    typeId() {
      return this.$route.params.typeId;
    },
    sheetsId() {
      return this.$route.params.sheetsId;
    }
  },
  created() {
    this.setTechnical(this.getTechnicalById(this.technicalId));
  },
  methods: {
    ...mapActions(useTechnicalsStore, [
      "updateTechnicals",
      "destroyTechnical",
      "setTechnical",
    ]),
    cancel() {
      this.$router.push({
        name: "sheet",
        params: {
          sheetId: this.sheetId,
          sheetsId: this.sheetsId,
        },
      });
    },
    store() {
      this.loading = true;
      this.updateTechnical.sheet_id = this.sheetId;
      this.updateTechnical.synchronized = 0;
      this.updateTechnical.id = this.technical.id;

      this.updateTechnicals(this.updateTechnical).then(() => {
        this.loading = false;
        this.cancel()
      });
    },
    technicalInformationsFilm(informationsFilm) {
      this.updateTechnical = informationsFilm;
    },
    technicalInformationsSoftener(informationsSoftener) {
      this.updateTechnical = informationsSoftener;
    },
    destroy() {
      this.destroyTechnical(this.technical.id).then(() => {
        this.loading = false;
        this.cancel()
      });
    },
  },
};
</script>
