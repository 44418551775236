import apiClient from "@/api/client";

export default {
  fetchContracts() {
    return apiClient.get("/contracts");
  },
  fetchContract(id) {
    return apiClient.get("/contracts/" + id);
  },
};
