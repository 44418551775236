import observationApi from '@/api/observation'
import local from '@/database/local.js'
import { defineStore } from 'pinia';

export const useObservationsStore = defineStore('observations', {
  state: () => ({
    loading: false,
    observations: [],
  }),
  actions: {
    emptyObservations() {
      this.observations = []
    },
    getObservations() {
      this.loading = true
      local
        .observations
        .toArray()
        .then(observations => {
          this.observations = observations
          this.loading = false
        });
    },
    fetchObservations() {
      this.loading = true
      return observationApi
        .fetchObservations()
        .then((response) => {
          local.observations.bulkPut(response.data.observations)
          this.loading = false
        })
        .then(() => {
          this.getObservations()
        })
    },
  }
})
