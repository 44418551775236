<template>
  <v-list>
    <template v-for="building in buildings">
      <building-list-tile
        :building="building"
        :key="building.id"
      ></building-list-tile>
      <v-divider :key="'divider-' + building.id"></v-divider>
    </template>
  </v-list>
</template>

<script>

import BuildingListTile from "@/components/building/ListTile"

export default {
  props: [
    'buildings',
  ],
  components: {
    'building-list-tile': BuildingListTile,
  }
}

</script>
