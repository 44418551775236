<template>
  <v-list-item
    :to="{ name: 'building', params: { buildingId: building.id }}"
  >
    <v-list-item-content>
      <v-list-item-title>
        {{ building.name }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ building.zip }} {{ building.locality }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>

export default {
  props: [
    'building'
  ],
  computed: {

  },
}

</script>
