import apiClient from "@/api/client";

export default {
  fetchTechnicals() {
    return apiClient.get("/technicals");
  },
  fetchTechnical(id) {
    return apiClient.get("/technicals/" + id);
  },
  createTechnical(technical) {
    return apiClient.post('/technicals', technical)
  },
  updateTechnical(technical) {
    return apiClient.post('/technicals/' + technical.id, technical)
  },
  deleteTechnical(id) {
    return apiClient.delete('/technicals/' + id)
  },
};
