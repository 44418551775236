<template>
  <v-container>
    <v-card>
      <v-card-title>
        Interventions
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="contracts"
        :search="search"
      >
        <template v-slot:item.building_id="{ item }">
          {{ getBuilding(item.building_id) }}
        </template>
        <template v-slot:item.network_id="{ item }">
          {{ getNetwork(item.network_id) }}
        </template>
        <template v-slot:item.type_id="{ item }">
          {{ getType(item.type_id) }}
        </template>
        <template v-slot:item.technician_id="{ item }">
          {{ getTechnician(item.technician_id) }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>

import { mapState } from 'pinia'
import { useBuildingsStore } from '../store/modules/building';
import { useContractsStore } from '../store/modules/contract';
import { useNetworksStore } from '../store/modules/network';
import { useTechniciansStore } from '../store/modules/technician';
import { useTypesStore } from '../store/modules/type';

export default {
  data() {
    return {
      search: '',
      headers: [
        { text: 'Numéro', value: 'number' },
        { text: 'Immeuble', value: 'building_id' },
        { text: 'Reséau', value: 'network_id' },
        { text: 'Type', value: 'type_id' },
        { text: 'Technicien', value: 'technician_id' },
      ],
    }
  },
  computed: {
    ...mapState(useBuildingsStore, [
      'getBuildingById',  
    ]),
    ...mapState(useNetworksStore, [
      'getNetworkById',
    ]),
    ...mapState(useTechniciansStore, [
      'getTechnicianById',
    ]),
    ...mapState(useTypesStore, [
      'getTypeById',
    ]),
    ...mapState(useContractsStore, [
      'contracts',
    ]),
  },
  methods: {
    getBuilding(buildingId) {
      let building = this.getBuildingById(buildingId);

      return building.name
    },
    getNetwork(networkId) {
      let network = this.getNetworkById(networkId);

      return network.name
    },
    getType(typeId) {
      let type = this.getTypeById(typeId);

      if (type) {
        return type.name
      }

      return ''
    },
    getTechnician(technicianId) {
      let technician = this.getTechnicianById(technicianId);

      if (technician) {
        return technician.full_name
      }

      return ''
    }
  }
}

</script>