<template>
  <v-container>
    <template v-if="images">
      <div v-for="(image, index) in images" :key="index" class="mb-2">
        <v-img 
          :src="image.hasOwnProperty('dataUrl') ? image.dataUrl : image.url" 
          contain
        ></v-img>
        <v-btn
          color="error"
          block
          depressed
          v-if="image.id"
          @click="destroy(img.id, index)"
        >Supprimer la photo</v-btn>
      </div>
    </template>
    <v-layout class="mt-4 mb-4" justify-center>
      <v-btn 
        depressed
        class="mr-2"
        @click="cancel()"
      >
        Retour
      </v-btn>
    </v-layout>
  </v-container>
</template>

<script>

import { mapActions, mapState } from 'pinia';
import { useInterventionsStore } from '@/store/modules/intervention';

export default {
  data() {
    return {}
  },
  computed: {
    ...mapState(useInterventionsStore, [
      'intervention',
      'getInterventionById',
    ]),
    interventionId() {
      return parseInt(this.$route.params.interventionId)
    },
    intervention() {
      return this.getInterventionById(this.interventionId)
    },
    sheetsId() {
      return this.$route.params.sheetsId;
    },
    images() {
      if (this.intervention) {
        if (this.intervention.images.length) {
          return this.intervention.images
        }
      }

      return false
    }
  },
  methods: {
    ...mapActions(useInterventionsStore, [
      'destroyImages',
      'setIntervention',
    ]),
    cancel() {
      this
      .$router
      .push({
        name: 'sheet',
        params: {
          sheetId: this.intervention.sheet_id,
          sheetsId: this.sheetsId,
        }
      })
    },  
    destroy(imgId, index) {
      this.destroyImages({
        interventionId: this.interventionId,
        imgId: imgId,
        index: index
      })
    }
  }
}

</script>
