<template>
  <v-list>
    <template v-for="sheet in sheets">
      <template v-if="sheet">
        <sheet-list-tile :key="sheet.id" :sheet="sheet" :sheetsId="sheetsId"></sheet-list-tile>
        <v-divider :key="'divider-' + sheet.id"></v-divider>
      </template>
    </template>
  </v-list>
</template>

<script>

import SheetListTile from "@/components/sheet/ListTile";

export default {
  props: [
    'sheets',
    'sheetsId'
  ],
  components: {
    SheetListTile,
  }
}

</script>
