import buildingApi from "@/api/building";
import local from "@/database/local.js";
import { defineStore } from 'pinia'

export const useBuildingsStore = defineStore('buildings', {
  state: () => ({
    loading: false,
    buildings: [],
  }),
  actions: {
    emptyBuildings() {
      this.buildings = []
    },
    getBuildings() {
      this.loading = true
      local
        .buildings
        .toArray()
        .then(buildings => {
          this.buildings = buildings.sort((a, b) => a.name?.localeCompare(b.name))
          this.loading = false
        });
    },
    searchBuildings(search) {
      local
        .buildings
        .filter((building) => {
          return building.name?.toLowerCase().indexOf(search.toLowerCase()) >= 0
        })
        .toArray()
        .then(buildings => {
          this.buildings = buildings
          this.loading = false
        })
    },
    fetchBuildings() {
      this.loading = true
      return buildingApi
        .fetchBuildings()
        .then((response) => {
          local.buildings.bulkPut(response.data)
          this.loading = false
        })
        .then(() => {
          this.getBuildings()
        })
    },
    syncBuildings() {
      return local.buildings
        .filter(building => building.synchronized === 0)
        .toArray()
        .then(buildings => {
          buildings.forEach(building => {
            buildingApi
              .updateBuilding(building)
              .then(response => {
                local.buildings.update(building.id, response.data.building)
              })
          })
        })
    },
    storeInfo(buildingId, info) {
      local.buildings
        .update(buildingId, {
          'info': info,
          'synchronized': 0,
        })
    }
  },
  getters: {
    getBuildingById: (state) => {
      return (buildingId) => state.buildings.find((building) => building.id === buildingId)
    },
    getBuildingsByBuildingId: (state) => {
      return (buildingId) => state.buildings.filter((building) => building.building_id === buildingId)
    },
  },
})
